<template>
  <div class="heading-elements d-flex">
      <slot name="actions-container"></slot>
      <b-button v-if="availableActions.includes('collapse')" :title="isCollapsed ? 'Expand' : 'Collapse'"
                variant="link"
                :class="actionContainerClasses.collapse"
                class="btn-icon card-action-collapse">
        <a data-action="collapse" :class="{ rotate: isCollapsed }" @click="$emit('collapse')">
<!--          <feather-icon icon="ChevronDownIcon" size="16"/>-->
          <b-icon-chevron-down/>
        </a>
      </b-button>
      <b-button v-if="availableActions.includes('refresh')" title="Refresh"
                variant="link"
                :class="actionContainerClasses.refresh"
                class="btn-icon">
        <a data-action="reload" @click="$emit('refresh')">
<!--          <feather-icon icon="RotateCwIcon" size="14"/>-->
          <b-icon-arrow-clockwise/>
        </a>
      </b-button>
      <b-dropdown v-if="availableActions.includes('options')" title="Options"
                  no-caret dropright :right="$store.state.appConfig.isRTL"
                  variant="link"
                  :class="actionContainerClasses.options"
                  toggle-class="btn-icon">
        <template #button-content>
<!--          <feather-icon icon="MoreVerticalIcon" size="14"/>-->
          <b-icon-three-dots-vertical style="width: 14px; height: 14px;"/>
        </template>
        <slot name="options-container"></slot>
      </b-dropdown>
      <b-button v-if="availableActions.includes('close')" title="Close"
                variant="link"
                :class="actionContainerClasses.close"
                class="btn-icon">
        <a data-action="close" @click="$emit('close')">
<!--          <feather-icon icon="XIcon" size="14"/>-->
          <b-icon-x/>
        </a>
     </b-button>
  </div>
</template>

<script>
export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    availableActions: {
      type: Array,
      default: () => [],
    },
    actionContainerClasses: {
      type: Object,
      default: () => ({ // <= note the parenthesis
        collapse: null,
        refresh: null,
        options: null,
        close: null,
      })
    },
  },
}
</script>

<style>
  .heading-elements button:not(:last-child) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .heading-elements button:last-child {
    padding-left: 0;
    padding-right: 0;
  }
  .heading-elements *:last-child button {
    padding-left: 0;
    padding-right: 0;
  }
</style>
