<template>
    <b-button-group id="table-layout-actions" size="sm" class="text-danger">
      <!-- Refresh -->
<!--      <b-button variant="secondary">
        <b-icon icon="arrow-clockwise" aria-label="Is Busy" @click="onRefresh"></b-icon>
      </b-button>-->


      <!-- Selected -->
<!--      <b-dropdown v-if="selectableEnabled" lazy :variant="button.variant" size="sm" left text="Select" boundary="window" :popper-opts="{ positionFixed: true }">
        <template #button-content>
          <b-icon icon="check-square" aria-label="Select"></b-icon>
        </template>
        <b-dropdown-header id="dropdown-header-label">Select</b-dropdown-header>
        <b-dropdown-divider/>
        <b-dropdown-item-button button-class="w-100" @click="onSelectAll">
          <feather-icon icon="CheckSquareIcon"/>
          <span class="align-middle ml-50">Select All</span>
        </b-dropdown-item-button>
        <b-dropdown-item-button button-class="w-100" @click="onUnselectAll">
          <feather-icon icon="SquareIcon"/>
          <span class="align-middle ml-50">Unselect All</span>
        </b-dropdown-item-button>
        <template v-if="selectedCount > 0">
          <b-dropdown-divider />
          <b-dropdown-item-button button-class="w-100" @click="$emit('delete-selected')">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete Selected</span>
          </b-dropdown-item-button>
        </template>
      </b-dropdown>-->

      <!-- Columns -->
      <b-dropdown id="table-layout-action-columns" lazy :variant="button.variant" size="sm" left text="Columns" title="Columns" boundary="window" :popper-opts="{ positionFixed: true }">
        <template #button-content>
          <b-icon icon="layout-three-columns" aria-label="Columns"></b-icon>
        </template>
        <b-dropdown-header id="dropdown-header-label">Columns</b-dropdown-header>
        <b-dropdown-divider/>
        <b-dropdown-form v-for="field in fieldsSelectable" :key="field.key">
          <b-form-checkbox v-model="field.visible" :value="true" :unchecked-value="false" :disabled="field.required" @input="onColumnCheck">
            {{ field.label }}
          </b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>

      <!-- Sort ASC/DESC -->
      <b-dropdown id="table-layout-action-sorting" lazy
                  :variant="button.variant"
                  size="sm"
                  right dropright split
                  title="Sorting"
                  toggle-class="border-0"
                  split-class="border-0"
                  boundary="window"
                  :popper-opts="{ positionFixed: true }"
                  menu-class="dropdown-menu-sort-by"
                  @click="onSortOrder">
        <template #button-content>
          <b-icon :icon="isSortDesc ? 'sort-alpha-down-alt' : 'sort-alpha-up'" aria-label="Sort"></b-icon>
        </template>
        <b-dropdown-header id="dropdown-header-label">Sort By</b-dropdown-header>
        <b-dropdown-divider/>

        <b-dropdown-group v-b-toggle.collapse-sort-fields
                          header-classes="text-muted d-flex justify-content-between"
                          role="tab">
          <template #header>
            Fields
            <feather-icon icon="ChevronDownIcon" size="16" :class="{ rotate: sort.fields.isVisible }"/>
          </template>
          <template #default>
            <b-collapse id="collapse-sort-fields" v-model="sort.fields.isVisible" accordion="sort-accordion" role="tabpanel">
              <b-dropdown-form>
                <b-form-radio v-model="sortedBy" :value="'id'" @change="onSortBy">
                  ID
                </b-form-radio>
              </b-dropdown-form>
              <b-dropdown-form v-for="field in fieldsSortable" :key="field.key">
                <b-form-radio v-model="sortedBy" :value="field.value" :disabled="field.required" @change="onSortBy">
                  {{ field.text }}
                </b-form-radio>
              </b-dropdown-form>
            </b-collapse>
          </template>
        </b-dropdown-group>
        <template v-if="sortComparators.length">
          <b-dropdown-divider/>
          <b-dropdown-group v-b-toggle.collapse-sort-comparators
                            header-classes="text-muted d-flex justify-content-between"
                            role="tab">
            <template #header>
              Comparator
              <feather-icon icon="ChevronDownIcon" size="16" :class="{ rotate: sort.comparators.isVisible }"/>
            </template>
            <template #default>
              <b-collapse id="collapse-sort-comparators" v-model="sort.comparators.isVisible" accordion="sort-accordion" role="tabpanel">
                <b-dropdown-form v-for="item in sortComparators" :key="item.key">
                  <b-form-radio v-model="sortedComparatorKey"
                                :value="item.key"
                                @change="onSortCompare(item)">
                    {{ item.text }}
                  </b-form-radio>
                </b-dropdown-form>
              </b-collapse>
            </template>

          </b-dropdown-group>

        </template>


      </b-dropdown>

      <!-- Paging -->
      <b-dropdown id="table-layout-action-page-size" lazy :variant="button.variant" size="sm" title="Paging" toggle-class="text-decoration-none px-1" no-caret right boundary="window" :popper-opts="{ positionFixed: true }">
        <template #button-content>
          <b-icon icon="collection" class="mr-0"/><span class="sr-only">Page Size</span> <small class="align-start"> - {{ pagingSize === 0 ? 'All' : pagingSize }}</small>
        </template>
        <b-dropdown-header id="dropdown-header-label">Page Size</b-dropdown-header>
        <b-dropdown-divider/>
        <b-dropdown-item-button v-for="size in pagingSizes"
                                :key="size" button-class="w-100"
                                :active="size === pagingSize"
                                active-class="bg-light-primary" @click="onPageSize(size)">
          {{ size === 0 ? 'All' : size }}
        </b-dropdown-item-button>
<!--        <b-dropdown-text text-class="text-xs" variant="danger">
          <strong>Changing the page size will clear all paged data, and go back to page 1</strong>
        </b-dropdown-text>-->
      </b-dropdown>

      <!-- Filters -->
      <b-button v-if="filters" id="table-layout-action-filters" size="sm" :variant="button.variant" :title="filtersVisible ? 'Hide Filters' : 'Show Filters'" class="d-flex align-items-center" @click="onToggleFilter">
        <b-icon :icon="filtersVisible ? 'funnel' : 'funnel-fill'" aria-label="Filters"/>
        <b-badge v-if="filtersActive > 0 && !filtersVisible" variant="light-primary" class="round font-small-1">{{ filtersActive }}</b-badge>
      </b-button>

    </b-button-group>
</template>


<script>
  export default {
    name: 'TableActions',
    props: {
      selectableFields: { type: Array, required: true },
      sortableFields: { type: Array, required: true },
      sortBy: { type: String, default: null },
      sortDesc: { type: Boolean, required: true },
      sortComparator: { type: Function, default: null },
      sortComparatorKey: { type: String, default: null },
      sortComparators: { type: Array, required: true },
      pagingSize: { type: Number, required: true },
      pagingSizes: { type: Array, required: true },
      selectedCount: { type: Number, required: true },
      selectableEnabled: { type: Boolean, required: true },
      filters: { type: Object, default: null },
      filtersActive: { type: Number, required: true },
      filtersVisible: { type: Boolean, required: true }
    },
    data() {
      return {
        button: {
          variant: 'transparent'
        },
        sortedBy: this.sortBy,
        sortedComparator: this.sortComparator,
        sortedComparatorKey: this.sortComparatorKey || null,
        sort: {
          fields: {
            isVisible: true
          },
          comparators: {
            isVisible: false
          }
        }
      }
    },
    computed: {
      fieldsSelectable() {
        return this.selectableFields;
      },
      fieldsSortable() {
        return this.sortableFields;
      },
      isSortDesc() {
        return this.sortDesc
      },
    },
    watch: {
      sortBy(n, o) {
        if(n !== o) {
          this.sortedBy = n
        }
      }
    },
    methods: {
      onRefresh() {
        this.$emit('refresh')
      },
      onSortOrder() {
        this.$emit('sort-order')
      },
      onSortBy() {
        this.sortedComparator = null
        this.sortedComparatorKey = null
        this.$emit('sort-by', this.sortedBy)
        //this.$emit('sort-compare', null)
      },
      onSortCompare(item) {
        this.sortedBy = 'id'
        this.$emit('sort-compare', item)
      },
      onPageSize(size) {
        this.$emit('page-size', size)
      },
      onSelectAll() {
        this.$emit('select-all')
      },
      onUnselectAll() {
        this.$emit('unselect-all')
      },
      onColumnCheck() {
        this.$store.dispatch('storageLocal/updateItem', {
          key: this.$route.name,
          subKey: 'fields',
          value: this.selectableFields.map(field => ({ key: field.key, visible: field.visible }))
        })
      },
      onToggleFilter() {
        this.$emit('toggle-filter')
      },
      toggleSortCollapse() {
        this.sort.fields.isVisible = !this.sort.fields.isVisible
        this.sort.comparators.isVisible = !this.sort.comparators.isVisible
      }
    }
  }
</script>

<style>
/*.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}*/

.dropdown-menu-sort-by {
  min-width: 14rem!important;
}
.dropdown-header svg {
  transition: all 0.25s ease-out;
}

.dropdown-header svg.rotate {
  transform: rotate(-180deg);
}


.btn-transparent.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
</style>

