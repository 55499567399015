<template>
  <b-row v-if="visible">
    <b-col v-if="state.filters.enabled && state.filters.visible" :class="getColClass(state.filters.cols)">
			<!-- Filters -->
			<b-card-actions id="table-layout-filters"
											action-collapse :collapsed="state.filters.collapsed"
                      card-class="mb-1" header-class="py-75" body-class="pb-75">
				<template #title>
          <b-card-title class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
              <b-icon icon="filter" class="mr-50" style="width: 16px; height: 16px" />
              <span class="align-middle">Filters</span>
            </div>
            <b-badge v-if="hasActiveFilters" variant="light-primary" class="round font-small-1 mr-1">
              {{ computedActiveFilters.length }}
            </b-badge>
          </b-card-title>
				</template>
				<slot name="filters"></slot>
			</b-card-actions>

      <slot name="sidebar"></slot>
    </b-col>
    <b-col>

      <slot name="alert">

      </slot>

			<!-- Table -->
			<b-card no-body class="table-card mb-0">

				<!-- Table Header -->
        <b-card-header class="d-flex justify-content-between align-items-center">

          <table-actions
              :paging-size="table.paging.size"
              :paging-sizes="table.paging.options.sizes"
              :selectable-fields="computedFieldsSelectable"
              :sortable-fields="computedFieldsSortable"
              :sort-by="table.sorting.by"
              :sort-desc="table.sorting.desc"
              :sort-comparator="table.sorting.comparator"
              :sort-comparator-key="table.sorting.comparatorKey"
              :sort-comparators="table.sorting.comparators"
              :selectable-enabled="state.selectable.enabled"
              :selected-count="table.selected.items.length"
              :filters="table.filters"
              :filters-active="computedActiveFilters.length"
              :filters-visible="state.filters.visible"
              @page-size="onPageSize"
              @sort-by="onSortBy"
              @sort-compare="onSortCompare"
              @sort-order="onSortOrder"
              @select-all="selectAllRows"
              @unselect-all="clearSelected"
              @delete-selected="deleteSelectedItems"
              @toggle-filter="toggleFilters"
          />

          <!-- Search -->
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group v-if="isSearchable" class="input-group-merge mr-1">
              <b-form-input id="table-layout-search"
                            v-model="table.search.value"
                            size="md"
                            :debounce="500"
                            autocomplete="off"
                            placeholder="Search..."
                            class="search-product"
              />
              <b-input-group-append v-if="table.search.value" is-text>
                <feather-icon icon="XIcon" class="text-muted" @click="table.search.value = ''" />
              </b-input-group-append>
              <b-input-group-append>
                <b-dropdown id="table-layout-search-options" right :text="computedSearchTypeText" size="sm" variant="transparent" toggle-class="search-dropdown">
                  <b-dropdown-item v-for="type in table.search.options.types" :key="type.value" @click="table.search.type = type.value">
                    {{ type.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>


            <!-- Table Options -->
            <b-dropdown id="table-layout-options"
                        variant="link"
                        no-caret dropleft
                        :right="$store.state.appConfig.isRTL"
                        :toggle-class="['px-1']"
                        :class="{ show: tour.props.options.show }" >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-group header="Table Options">
                <!-- Print -->
                <b-dropdown-item id="table-layout-option-print" @click="print('table-container')">
                  <font-awesome-icon icon="fa-solid fa-print"></font-awesome-icon>
                  <span class="align-middle ml-50">Print</span>
                </b-dropdown-item>

                <!-- Export -->
                <b-dropdown-item id="table-layout-option-export" v-b-modal.export-modal>
                  <font-awesome-icon icon="fa-solid fa-file"></font-awesome-icon>
                  <span class="align-middle ml-50">Export</span>
                  <export-modal :items="computedItems"
                                 :items-sort-by="sortBy"
                                 :file-name="exportFileName"
                                 :select-fields="exportSelectFields"
                                 :exclude-fields="exportExcludeFields"/>
                </b-dropdown-item>
                <!--                <b-dropdown-item v-if="state.filters.enabled" @click="toggleFilters()">
                                  <font-awesome-icon icon="fa-solid fa-filter"></font-awesome-icon>
                                  <span class="align-middle ml-50">{{ state.filters.visible ? 'Hide' : 'Show' }} Filters</span>
                                </b-dropdown-item>-->

                <!-- Help -->
                <b-dropdown-item id="table-layout-option-help" @click="$tours['table-tour'].start()">
                  <font-awesome-icon icon="fa-solid fa-circle-question"></font-awesome-icon>
                  <span class="align-middle ml-50">Help</span>
                </b-dropdown-item>

                <!-- Dev -->
                <template v-if="$can('debug', this.$route.name)">
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group header="Developer">
                    <b-dropdown-item @click="state.debug.enabled = !state.debug.enabled">
                      <font-awesome-icon icon="fa-solid fa-code"></font-awesome-icon>
                      <span class="align-middle ml-50">Debug</span>
                    </b-dropdown-item>
<!--                    <b-dropdown-item @click="toggleSelectable">
                      <font-awesome-icon v-if="state.selectable.enabled" icon="fa-solid fa-square-check" />
                      <font-awesome-icon v-else icon="fa-solid fa-square" />
                      <span class="align-middle ml-50">Selectable</span>
                    </b-dropdown-item>-->
                  </b-dropdown-group>
                </template>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </b-card-header>

				<!-- Table -->
				<div id="table-container">

					<b-table id="table" ref="table"
									 class="position-relative"
                   table-class="table-flush"
                   thead-class="thead-light"
                   thead-tr-class="border-0"
                   tbody-class="border-0"
                   tbody-tr-class="tbody-tr-border"
									 responsive
                   primary-key="id"
									 :busy="table.loading"
									 :items="computedItems"
									 :fields="computedFields"
									 :filter="{ search: table.search, filters: table.filters }"
									 :sort-by="table.sorting.by"
									 :sort-desc="table.sorting.desc"
									 :sort-compare="table.sorting.comparator"
									 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									 :per-page="table.paging.size"
									 :current-page="table.paging.page"
									 :tbody-transition-props="table.transition"
									 :show-empty="true"
									 :empty-text="emptyText"
									 :empty-filtered-text="emptyFilteredText"
									 :selectable="state.selectable.enabled"
									 :select-mode="table.selected.mode"
									 @row-selected="onRowSelected"
									 @sort-changed="onSort">

						<!-- Table Busy Slot -->
						<template #table-busy>
							<slot name="overlay">
								<div class="text-center my-2">
									<b-spinner class="align-middle"></b-spinner>
								</div>
							</slot>
						</template>

						<template #row-details="{ item, index, toggleDetails }">
							<slot name="row-details" :item="item" :index="index" :toggleDetails="toggleDetails"></slot>
						</template>

						<!-- Table Cell Slots: cell(key)  -->
						<template v-for="slot in computedFieldsSlots" #[slot]="data">
							<slot :name="slot" :data="data">
								{{ data.value }} <!-- Fallback -->
							</slot>
						</template>

						<!-- Override Actions Cell: and put dropdown-items inside of table-row-actions component -->
						<template #cell(row-options)="data">
							<table-row-options :index="data.index" :tour-props="tour.props.row">
								<slot name="cell(row-options)" :data="data"></slot>
							</table-row-options>
						</template>

						<!-- Table Selected Slot -->
						<template #cell(selected)="{index, rowSelected}">
							<div class="d-flex justify-content-center">
								<b-checkbox :checked="rowSelected" :disabled="!state.selectable.enabled" class="p-0" @change="onSelectCheckbox(index)"></b-checkbox>
							</div>
						</template>

					</b-table>
				</div>

				<!-- Table Footer -->
				<div id="table-footer" class="mx-2 mb-2">
					<b-row>
						<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start">
							<div id="table-layout-paging-details">
								<span v-if="table.items.length > 0"  class="text-muted">Showing {{
                    computedPagination.from
                  }} to {{ computedPagination.to }} of {{ computedPagination.of }} entries</span>
								<span v-else class="text-muted">Showing 0 of {{ computedPagination.of }} entries</span>
							</div>
						</b-col>

						<!-- Cache -->
						<b-col v-if="cacheTime" class="d-flex align-items-center justify-content-center">
							<div class="text-muted font-small-3">
								<div v-if="cacheExpired">Cache Expired</div>
								<last-modified v-else :date="cacheTime" text="Cached "></last-modified>
							</div>
						</b-col>

						<!-- Pagination -->
						<b-col class="d-flex align-items-center justify-content-center justify-content-sm-end">
							<!-- :per-page="0" on b-table disables table pagination, however it does not disable the paging on the b-pagination component.
                    In order to trick b-pagination to only show 1 page when :per-page="0" is to make :total-rows toggle it's value. -->
							<b-pagination v-if="!table.loading" id="table-layout-paging-pagination" v-model="table.paging.page"
														:total-rows="table.paging.size > 0 ? computedItemsTotal : 0"
														:per-page="table.paging.size"
														first-number
														last-number
														class="mb-0 mt-1 mt-sm-0"
														prev-class="prev-item"
														next-class="next-item"
														@input="onPageChange"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18"/>
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18"/>
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
			</b-card>

			<!-- Internal Debug -->
			<template v-if="state.debug.enabled">
        <b-row class="mt-2">
          <b-col cols="4">
            <debug title="Table">
              {{ table }}
            </debug>
          </b-col>
          <b-col cols="4">
            <debug title="TableLayout Properties">
              {{ this.$props }}
            </debug>
          </b-col>
          <b-col cols="4">
            <debug title="TableLayout State">
              {{ state }}
            </debug>
          </b-col>
        </b-row>
			</template>
    </b-col>

    <!-- Tour -->
    <tour ref="table-tour" name="table-tour" :steps="tour.steps" :callbacks="tour.callbacks"></tour>
  </b-row>

</template>

<script>
import _, {isEmpty} from 'lodash'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import print from '@/mixins/print.mixin';
import storageLocal from '@/mixins/storage.local.mixin'
import TableActions from '@/components/TableActions.vue';
import ExportModal from '@/components/ExportModal.vue';
import Tour from '@/components/Tour.vue';
import Fuse from 'fuse.js';
import LastModified from '@/components/LastModified.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';

export default {
  name: 'TableLayout',
  components: {
    TableRowOptions,
    LastModified,
    ExportModal,
    TableActions,
    vSelect,
    BCardActions,
    Tour
  },
  mixins: [ print, storageLocal ],
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: null
    },
    filtersOptions: {
        type: Object,
        default() {
            return {
                enabled: false,
                visible: false,
                collapsed: true,
                cols: 12
            }
        }
    },
    exportSelectFields: {
      type: Array,
      default() {
        return []
      }
    },
    exportExcludeFields: {
      type: Array,
      default() {
        return []
      }
    },
    exportFileName: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      required: true
    },
		visible: {
			type: Boolean,
			default: true
		},
    transition: {
      type: String,
      default: 'fade'
    },
    subscriptions: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: ''
    },
    isSearchable: {
      type: Boolean,
      default: true
    },
    paging: {
      type: Object,
      default: null
    },
    sorting: {
      type: Object,
      default: null
    },
    emptyText: {
      type: String,
      default: 'No data available'
    },
    emptyFilteredText: {
      type: String,
      default: 'No matching records found'
    },
    cacheExpired: {
      type: Boolean,
      default: null
    },
    cacheTime: {
      type: [String, Date],
      default: null
    },
    funcDelete: {
      type: Function,
      default: (item) => { }
    },
    funcRefresh: {
      type: Function,
      default: () => { }
    },
    tourProps: {
      type: Object,
      default: () => ({
        options: {
          show: false
        },
        row: {
          options: {
            show: false,
            index: 0
          }
        }
      })
    }
  },
  data() {
    return {
      table: {
        items: this.items,
        fields: this.fields,
        filters: this.filters,
				visible: this.visible,
        loading: this.loading,
        loaded: 0,
        subscriptions: this.subscriptions,
        selected: {
          items: [],
          mode: 'multi'
        },
        paging: {
          page: this.paging?.page || 1,
          size: (this.paging?.size !== undefined) ? this.paging.size : 25,
          options: this.paging?.options || {
            sizes: [10, 25, 50, 100, 0]
          }
        },
        search: {
          type: '\'',
          value: this.search,
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        sorting: {
          by: this.sorting?.by || 'id',
          desc: this.sorting?.desc || false,
          comparator: this.sorting?.comparator || null,
					comparatorKey: this.sorting?.comparatorKey || null,
          comparators: this.sorting?.comparators || []
        },
        transition: {
          name: this.transition,
        },
      },
      tour: {
        steps: [
          {
            target: '#table-layout-actions',
            header: { title: 'Table Actions' },
            content: 'Actions allow you to manipulate the data being displayed in the table.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-action-columns__BV_toggle_',
            header: { title: 'Columns' },
            content: 'The columns option allows you to toggle which fields are displayed in the table.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-action-sorting__BV_button_',
            header: { title: 'Sorting Direction' },
            content: 'The sorting direction option allows you to change the direction in which the data is displayed.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-action-sorting__BV_toggle_',
            header: { title: 'Sorting By Field' },
            content: 'The sorting by option allows you to change which field the table is sorted on.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-action-page-size__BV_toggle_',
            header: { title: 'Page Size' },
            content: 'The page size option allows you to change the number of rows displayed in the table.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-search',
            header: { title: 'Search' },
            content: 'The search function allows you to search table data, helping you find what you are looking for.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true }
          },
          {
            target: '#table-layout-search-options',
            header: { title: 'Search Options' },
            content: 'The search options allows you to refine how the search function looks for data.',
            params: { placement: 'bottom', enableScrolling: false, highlight: true },
          },
          {
            target: '#table-layout-options',
            header: { title: 'Table Options' },
            content: 'The table options menu shows additional table functionality.',
            params: { placement: 'top', enableScrolling: false, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = false
              resolve()
            })
          },
          {
            target: '#table-layout-option-print',
            header: { title: 'Print' },
            content: 'The print option sets the table into print mode, hiding unnecessary page details from being printed out.',
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = true
              resolve()
            })
          },
          {
            target: '#table-layout-option-export',
            header: { title: 'Export' },
            content: 'The export option opens a modal and allows you to select table fields to be exported.',
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = true
              resolve()
            })
          },
          {
            target: '#table-layout-option-help',
            header: { title: 'Help' },
            content: 'If you ever find your self confused, you can click this button to start the table layout tour.',
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = true
              resolve()
            })
          },
          {
            target: '#table-container',
            header: { title: 'Table' },
            content: 'The table content will display one of three states. Loading, No Content, or Rows',
            params: { placement: 'top', enableScrolling: false, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = false
              resolve()
            })
          },
          {
            target: '#table-layout-paging-details',
            header: { title: 'Paging Details' },
            content: 'The paging details show the total number of entries in the table, as well as how many of them are being displayed.',
            params: { placement: 'bottom', enableScrolling: true, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.row.options.show = false
              resolve()
            })
          },
          {
            target: '#table-layout-paging-pagination',
            header: { title: 'Pagination' },
            content: 'The pagination buttons allow you to change the current page of data being displayed.',
            params: { placement: 'bottom', enableScrolling: true, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.row.options.show = false
              resolve()
            })
          },
        ],
        props: this.tourProps || {
          options: { show: false },
          row: {
            options: {
              index: 0,
              show: false
            }
          }
        },
        callbacks: {
          onSkip: () => {
            this.tour.props.options.show = false
            this.tour.props.row.options.show = false
          },
          onFinish: () => {
            this.tour.props.options.show = false
            this.tour.props.row.options.show = false
          },
          onStop: () => {
            this.tour.props.options.show = false
            this.tour.props.row.options.show = false
          }
        }
      },
      state: {
        debug: {
          enabled: false
        },
        filters: {
          enabled: this.filtersOptions?.enabled || this.filters !== null,
          visible: this.filtersOptions?.visible || false,
          collapsed: this.filtersOptions?.collapsed || false,
          cols: this.filtersOptions?.cols || 12,
        },
        search: { enabled: this.isSearchable },
        selectable: { enabled: false }
      },
    }
  },
  computed: {
    computedSearch() {
      return this.table.search
    },

    computedFuseQuery() {
      const query = { $and: [] };

      if(this.table?.filters) {
        Object.values(this.table?.filters)
            .filter((filter) => filter.value)
            .forEach((f) => {
              const condition = { [f.key]: `="${f.value}"` };
              query.$and.push(condition);
            });
      }

      if(this.table?.search?.value) {
        const orConditions = this.computedSearchKeys.map((key) => ({
          [key]: `${this.table.search.type}${this.table.search.value}`
        }));
        const orCondition = { $or: orConditions };
        if(orConditions.length > 0) {
          query.$and.push(orCondition);
        }
      }
      return query;
    },

    computedFuseKeys() {
      const keys = new Set();
      if(this.table?.search?.value) {
        for (const key of this.computedSearchKeys) {
          keys.add(key);
        }
      }
      if(this.hasActiveFilters) {
        for (const key of this.computedFilterKeys) {
          keys.add(key);
        }
      }
      return Array.from(keys);
    },

    computedSearchKeys() {
      // Get all headers that are searchable
      return this.computedFields
          .filter((header) => header?.filterable === true)
          .reduce((acc, header) => {
            if (header.subKeys) {
              header.subKeys.forEach((subKey) => acc.push(subKey));
            } else {
              acc.push(header.key);
            }
            return acc;
          }, [])
    },

    computedFilterKeys() {
      if(!this.table?.filters) return [];
      return Object.values(this.table?.filters)?.map((filter) => filter.key) || [];
    },

    computedActiveFilters() {
      return this.table?.filters ? Object.values(this.table.filters).filter((filter) => filter.value) : [];
    },

    hasActiveFilters() {
      return this.computedActiveFilters.length > 0;
    },

    computedSearchTypeText() {
      return this.table.search.options.types.find((type) => type.value === this.table.search.type).text;
    },

    computedFields() {
      return this.table.fields.filter((field) => field.visible)
    },

    computedFieldsSlots() {
      return this.computedFields.map(field => `cell(${field.key})`)
    },

    computedFieldsSelectable() {
      return this.table.fields.filter(field => field.label)
    },

    computedFieldsSortable() {
      return this.computedFields.filter(field => field.sortable).map(field => ({ text: field.label, value: field.key }))
    },

    computedItems() {
      const {items} = this.table;
      if (!this.hasActiveFilters && isEmpty(this.table.search.value)) {
        this.$emit('filtered', false)
        return items;
      }

      if (this.computedFuseQuery.$and?.length) {
        const fuse = new Fuse(items, {
          useExtendedSearch: true,
          threshold: 0.1,
          shouldSort: true,
          keys: this.computedFuseKeys
        });
        this.$emit('filtered', true)
        return fuse.search(this.computedFuseQuery).map((result) => result.item);
      }
      this.$emit('filtered', true)
      return items;
    },

    computedItemsTotal() {
      return this.computedItems.length;
    },

    computedPagination() {
      let to = this.table.paging.size * (this.table.paging.page)
      if(this.table.paging.size === 0 || to >= this.computedItemsTotal) {
        to = this.computedItemsTotal
      }
      let from = 0
      if(this.computedItemsTotal > 0) {
        from = this.table.paging.size * (this.table.paging.page - 1) + 1
      }

      return {
        from: from,
        to: to,
        of: this.computedItemsTotal
      }
    },

  },
  watch: {
    items(value) {
      this.table.items = value
    },
    loading(value) {
      this.table.loading = value
    },
    search(value) {
      //console.log('prop search', value)
      this.table.search.value = value
    },
    'filtersOptions'(options) {
      //console.log('filtersOptions', options)
      if(options) {
        this.state.filters.cols = options?.cols ?? this.state.filters.cols
        this.state.filters.enabled = options?.enabled ?? this.state.filters.enabled
        this.state.filters.visible = options?.visible ?? this.state.filters.visible
        this.state.filters.collapsed = options?.collapsed ?? this.state.filters.collapsed
      }
    },
    'table.filters': {
      deep: true,
      handler(filters) {
        if(this.table?.filters) {
          this.$store.dispatch('storageLocal/updateItem', {
            key: this.$route.name,
            subKey: 'filters',
            value: Object.entries(this.table.filters).map(([key, value]) => ({
              key: key,
              value: value.value
            }))
          })
        }

        /** Optimized version of the above code **/
        this.setQueryFromFilters(filters)
      }
    },
    'table.search': {
      deep: true,
      handler(search) {
        if(search) {
          this.$store.dispatch('storageLocal/updateItem', {
            key: this.$route.name,
            subKey: 'search',
            value: {
              type: search?.type,
              value: search?.value
            }
          })
        }
        this.setQueryFromSearch(search)
      }
    },
    'table.paging': {
      deep: true,
      handler(paging) {
        this.setQueryFromPaging(paging)
      }
    }
  },
  mounted() {
    this.setDataFromQuery();

    this.addConditionalTourSteps();
    this.loadLocalSettings();
    this.table.fields.unshift({
      key: 'selected',
      label: '',
      sortable: false,
      filterable: false,
      visible: false,
      tdClass: ['align-middle', 'table-row-options']
    },)
    this.$emit('mounted', this.table)
  },
  beforeDestroy() {
    if(this.table?.subscriptions?.onCreate) {
      this.table.subscriptions.onCreate.unsubscribe()
    }
    if(this.table?.subscriptions?.onUpdate) {
      this.table.subscriptions.onUpdate.unsubscribe()
    }
    if(this.table?.subscriptions?.onDelete) {
      this.table.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    setDataFromQuery() {
      try {
        const { query } = this.$route;
        if(query) {
          if (query.filters) {
            const decodedQuery = JSON.parse(atob(query.filters));
            Object.keys(decodedQuery).forEach(key => {
              if (this.table.filters[key]) {
                this.table.filters[key].value = decodedQuery[key];
              }
            });
          }
          if (query.search) {
            const decodedQuery = JSON.parse(atob(query.search));
            //console.log('setDataFromQuery search', decodedQuery)
            this.table.search.type = decodedQuery.type;
            this.table.search.value = decodedQuery.value;
          }
          if (query.paging) {
            const decodedQuery = JSON.parse(atob(query.paging));
            this.table.paging.page = decodedQuery.page;
            this.table.paging.size = decodedQuery.size;
          }
          if (query.page) {
            this.table.paging.page = Number.parseInt(query.page, 10);
          }
          if (query.size) {
            this.table.paging.size = Number.parseInt(query.size, 10);
          }
        }
      }
      catch(e) {
        console.error('Error parsing query string: ', e);
      }
    },

    updateQuery(newQuery) {
      const normalizeQuery = (query) => Object.keys(query).reduce((acc, key) => {
        const value = query[key];
        // Check if value is a string and can be converted to a number
        acc[key] = (typeof value === 'string' && !Number.isNaN(Number(value))) ? Number(value) : value;
        return acc;
      }, {});

      // Normalize both the current and new query objects
      const currentQuery = normalizeQuery(this.$route.query);
      const updatedQuery = normalizeQuery({ ...currentQuery, ...newQuery });

      // Clean up null values
      Object.keys(updatedQuery).forEach(key => {
        if (updatedQuery[key] === null) {
          delete updatedQuery[key];
        }
      });

      // Log the transformed queries for debugging
      //console.log('Normalized current query:', currentQuery);
      //console.log('Normalized updated query:', updatedQuery);

      // Deep comparison
      const isQueryChanged = JSON.stringify(currentQuery) !== JSON.stringify(updatedQuery);

      if (isQueryChanged) {
        this.$router.push({ path: this.$route.path, query: updatedQuery });
      }
    },


    setQueryFromFilters(filters) {
      try {
        const generateQueryObject = (object) => {
          if(!object) return {};
          return Object.fromEntries(
              Object.entries(object)
                  .filter(([ignore, obj]) => obj.value)
                  .map(([key, obj]) => [key, obj.value])
          );
        }

        const queryObj = generateQueryObject(filters);
        const encodedQuery = btoa(JSON.stringify(queryObj));
        const newQuery = (Object.keys(queryObj).length > 0) ? { filters: encodedQuery } : { filters: null };
        this.updateQuery(newQuery);
      } catch(e) {
        console.error('Error generating query string: ', e);
      }
    },
    setQueryFromSearch(search) {
      try {
        const encodedQuery = search.value ? btoa(JSON.stringify({ value: search.value, type: search.type })) : null;
        this.updateQuery({ search: encodedQuery });
      } catch(e) {
        console.error('Error generating query string: ', e);
      }
    },
    setQueryFromPaging(paging) {
      try {
        const newQuery = {
          page: paging.page ? paging.page : null,
          size: paging.size ? paging.size : null
        };
        this.updateQuery(newQuery);
      } catch(e) {
        console.error('Error generating query string: ', e);
      }
    },

    getColClass(cols) {
      if(cols === 20) return 'col-20-percent'
      return `col-${cols}`
    },

    addConditionalTourSteps() {
      if(this.table.filters) {
        const items = [
          {
            target: '#table-layout-filters',
            header: { title: 'Table Filters' },
            content: 'Predefined filters that allow you to narrow down table rows. ',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = false
              resolve()
            })
          },
          {
            target: '#table-layout-filters .card-action-collapse',
            header: { title: 'Table Filters - Collapse/Expand' },
            content: 'The Table Filters card can be collapsed or expanded by clicking this button.',
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.options.show = false
              resolve()
            })
          }
        ]
        this.tour.steps.splice(0, 0, ...items)

        const index = this.tour.steps.findIndex(step => step.target === '#table-layout-action-page-size__BV_toggle_')
        this.tour.steps.splice(index + 1, 0, {
          target: '#table-layout-action-filters',
          header: { title: 'Filters' },
          content: 'If the table has predefined filters, this icon will appear. It will allow you to hide the filters card, while still indicating how many filters are applied.',
          params: { placement: 'bottom', enableScrolling: false, highlight: true }
        })
      }
      if(this.computedFieldsSlots.includes('cell(row-options)') && this.table.items.length) {
        const index = this.tour.steps.findIndex(step => step.target === '#table-container')
        const items = [
          {
            target: '#table-row-options-0',
            header: { title: 'Row Options' },
            content: 'Some tables will have options associated to the individual row.',
            params: { placement: 'top', enableScrolling: false, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.row.options.show = false
              resolve()
            })
          },
          {
            target: '#table-row-options-group-0',
            header: { title: 'Row Options' },
            content: 'Depending on which page you are on, different options will be available.',
            params: { placement: 'left', enableScrolling: false, highlight: true },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.row.options.show = true
              resolve()
            })
          }
        ]
        this.tour.steps.splice(index + 1, 0, ...items)
      }
    },
    loadLocalSettings() {
      if (this.canUseLocalSettings()) {
        const item = this.$store.getters['storageLocal/getItem'](this.$route.name)
        this.table.sorting.by = item?.sorting?.by || this.table.sorting?.by
        this.table.sorting.desc = item?.sorting?.desc || this.table.sorting?.desc
        this.table.search.type = item?.search?.type || this.table.search?.type
        this.table.search.value = item?.search?.value || this.table.search?.value
        this.table.paging.page = item?.paging?.page || this.table.paging?.page
        this.table.paging.size = item?.paging?.size || this.table.paging?.size

        if(item?.filters) {
          item.filters.forEach(filter => {
            const i = this.table.filters[filter.key]
            if(i) {
              i.value = filter.value
            }
          })
        }

        if(item?.fields) {
          item.fields.forEach(field => {
            const i = this.table.fields.find(f => f.key === field.key)
            i.visible = field.visible
          })
        }
      }
    },
    
    toggleFilters() {
      this.state.filters.visible = !this.state.filters.visible
    },
    toggleSelectable() {
      this.state.selectable.enabled = !this.state.selectable.enabled
      const selectedTableField = this.table.fields.find(field => field.key === 'selected')
      selectedTableField.visible = this.state.selectable.enabled
    },
    onPageChange(page) {
      this.table.paging.page = page
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'paging.page',
        value: this.table.paging.page
      })
    },
    onPageSize(size) {
      this.table.paging.size = size
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'paging.size',
        value: this.table.paging.size
      })
    },
    onSort(ctx) {
      this.table.sorting.by = ctx.sortBy
      this.table.sorting.desc = ctx.sortDesc
      this.table.sorting.comparator = null
			this.table.sorting.comparatorKey = null
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'sorting',
        value: this.table.sorting
      })
    },
    onSortOrder() {
      this.table.sorting.desc = !this.table.sorting.desc
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'sorting.desc',
        value: this.table.sorting.desc
      })
    },
    onSortBy(by) {
      this.table.sorting.by = by
      this.table.sorting.comparator = null
			this.table.sorting.comparatorKey = null
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'sorting.by',
        value: this.table.sorting.by
      })
    },
    onSortCompare(item) {
      this.table.sorting.by = 'id'
      this.table.sorting.comparator = item.comparator
			this.table.sorting.comparatorKey = item.key
    },
    sortBy() {
      return (a, b) => (this.table.sorting.desc
          ? _.toString(_.get(b, this.table.sorting.by)).localeCompare(_.toString(_.get(a, this.table.sorting.by)), undefined, { numeric: true, sensitivity: 'base' })
          : _.toString(_.get(a, this.table.sorting.by)).localeCompare(_.toString(_.get(b, this.table.sorting.by)), undefined, { numeric: true, sensitivity: 'base' })
      )
    },
    onRowSelected(items) {
      this.table.selected.items = items
    },
    selectAllRows() {
      this.$refs.table.selectAllRows()
    },
    selectRow(row) {
      this.$refs.table.selectRow(row)
    },
    unselectRow(row) {
      this.$refs.table.unselectRow(row)
    },
    onSelectCheckbox(row) {
      const isSelected = this.$refs.table.isRowSelected(row)
      if(isSelected) { this.unselectRow(row) }
      else { this.selectRow(row) }
    },
    clearSelected() {
      this.$refs.table.clearSelected()
    },
    deleteSelectedItems() {
      const promises = this.table.selected.items.map(async item => {
        await this.funcDelete(item)
      })
      Promise.all(promises).catch(error => { console.error(error) })
    },
  }
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    width: 90px;
  }

  @media print {
    .table-action{
      display:none;
    }
  }

  .tbody-tr-border {
    border-bottom: 0px solid #e9ecef;
  }

  .tbody-tr-border:last-child { border-bottom: none; }

  $search-dropdown-color: #b9b9c3;
  $search-dropdown-border-color: #d8d6de;
  $search-dropdown-border-color-focus: #212969;
  $chevron-color: black;
  $chevron-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='#{$chevron-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E";

  .search-dropdown {
    background: transparent;
    color: $search-dropdown-color;
    border-top: 1px solid $search-dropdown-border-color;
    border-bottom: 1px solid $search-dropdown-border-color;
  }

  .search-dropdown.dropdown-toggle::after {
    background-image: url($chevron-down)!important;
  }

  .input-group .search-dropdown{
    border-color: $search-dropdown-border-color;
  }
  .input-group:focus-within .search-dropdown{
    border-color: $search-dropdown-border-color-focus;
  }

  .input-group:focus-visible .search-dropdown{
    border-color: $search-dropdown-border-color-focus;
  }

  table#table .flip-list-move {
    transition: transform 1s;
  }

  table#table .fade-enter-active,
  table#table .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  table#table .fade-enter-from,
  table#table .fade-leave-to {
    opacity: 0;
  }

  table#table .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }
  table#table .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  table#table .slide-fade-enter-from,
  table#table .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

  table#table .list-enter-active,
  table#table .list-leave-active {
    transition: all 0.5s ease;
  }
  table#table .list-enter-from,
  table#table .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

</style>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>

<style lang="scss">
@media print {
  #table-layout-filters,
  #table-header,
  #table-footer,
  .table-row-options {
    display: none;
  }

  .table.b-table > thead > tr > [aria-sort] {
    background-image: none;
  }

	.table.b-table > * {
		font-size: 12px!important;
	}

	.table.b-table td, .table.b-table th {
		padding: 0.5rem!important;
		font-size: 12px!important;
		letter-spacing: normal!important;
		text-transform: none!important;
	}

}

</style>
