export const grades = [10, 11];
export const sexes = [
    { value: 'male', text: 'Male', icon: 'fa-solid fa-person' },
    { value: 'female', text: 'Female', icon: 'fa-solid fa-person-dress' },
];
export const genders = [
    { value: 'agender', text: 'Agender', icon: 'fa-solid fa-genderless' },
    { value: 'androgyne', text: 'Androgyne', icon: 'fa-solid fa-genderless' },
    { value: 'androgynous', text: 'Androgynous', icon: 'fa-solid fa-genderless' },
    { value: 'bigender', text: 'Bigender', icon: 'fa-solid fa-venus-mars' },
    { value: 'cis-female', text: 'Cis Female', icon: 'fa-solid fa-venus' },
    { value: 'cis-feminine', text: 'Cis Feminine', icon: 'fa-solid fa-venus' },
    { value: 'cis-male', text: 'Cis Male', icon: 'fa-solid fa-mars' },
    { value: 'cis-masculine', text: 'Cis Masculine', icon: 'fa-solid fa-mars' },
    { value: 'cis-woman', text: 'Cis Woman', icon: 'fa-solid fa-venus' },
    { value: 'demigirl', text: 'Demigirl', icon: 'fa-solid fa-venus' },
    { value: 'demiguy', text: 'Demiguy', icon: 'fa-solid fa-mars' },
    { value: 'female', text: 'Female', icon: 'fa-solid fa-venus' },
    { value: 'feminine', text: 'Feminine', icon: 'fa-solid fa-venus' },
    { value: 'femme', text: 'Femme', icon: 'fa-solid fa-venus' },
    { value: 'genderqueer', text: 'Genderqueer', icon: 'fa-solid fa-genderless' },
    { value: 'genderweird', text: 'Genderweird', icon: 'fa-solid fa-genderless' },
    { value: 'gender-fluid', text: 'Gender Fluid', icon: 'fa-solid fa-genderless' },
    { value: 'intergender', text: 'Intergender', icon: 'fa-solid fa-genderless' },
    { value: 'intersex', text: 'Intersex', icon: 'fa-solid fa-genderless' },
    { value: 'male', text: 'Male', icon: 'fa-solid fa-mars' },
    { value: 'man', text: 'Man', icon: 'fa-solid fa-mars' },
    { value: 'masculine', text: 'Masculine', icon: 'fa-solid fa-mars' },
    { value: 'neutrois', text: 'Neutrois', icon: 'fa-solid fa-genderless' },
    { value: 'nonbinary', text: 'Nonbinary', icon: 'fa-solid fa-genderless' },
    { value: 'other', text: 'Other', icon: 'fa-solid fa-genderless' },
    { value: 'pangender', text: 'Pangender', icon: 'fa-solid fa-venus-mars' },
    { value: 'third-gender', text: 'Third Gender', icon: 'fa-solid fa-genderless' },
    { value: 'trans-female', text: 'Trans Female', icon: 'fa-solid fa-transgender' },
    { value: 'trans-male', text: 'Trans Male', icon: 'fa-solid fa-transgender' },
    { value: 'trans-man', text: 'Trans Man', icon: 'fa-solid fa-transgender' },
    { value: 'trans-feminine', text: 'Trans Feminine', icon: 'fa-solid fa-transgender' },
    { value: 'trans-femme', text: 'Trans Femme', icon: 'fa-solid fa-transgender' },
    { value: 'trans-woman', text: 'Trans Woman', icon: 'fa-solid fa-transgender' },
    { value: 'woman', text: 'Woman', icon: 'fa-solid fa-venus' }
];
export const roles = ['Chaperone', 'Lead Chaperone'];
export const prefixes = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'];

export const days = [
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
];

export const yesNo = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const checkins = [
    { label: 'Received', value: true },
    { label: 'Missing', value: false },
]

const relationships = ['Mother', 'Father', 'Guardian', 'Sibling', 'Other']
const allergies = {
    types: [
        'adhesive',
        'adhesive bandage',
        'alcohol',
        'almond oil',
        'alpha glucoside inhibitor',
        'aluminum',
        'angiotensin ii receptor antagonist',
        'animal',
        'animal hair',
        'anti-infective agent',
        'apple juice',
        'aspartame',
        'bases and inactive substances',
        'bee venom',
        'biocide',
        'biocide in cosmetic',
        'caffeine',
        'carrot',
        'cat dander',
        'cherry',
        'chloroprocaine',
        'cinnamon',
        'cisatracurium',
        'citrus fruit',
        'coconut oil',
        'corn',
        'cosmetic',
        'dairy product',
        'dietary mushroom',
        'dog dander',
        'doxacurium',
        'drug',
        'drug in contact with skin',
        'drug vehicle',
        'dye',
        'eggs',
        'ertapenem',
        'erythromycin',
        'ethanol',
        'ethyl alcohol',
        'fish',
        'flavor',
        'fruit',
        'gauze',
        'grass pollen',
        'hay',
        'horse dander',
        'imipenem',
        'inhaled corticosteroids',
        'legumes',
        'lichen',
        'lobster',
        'macrolide antibiotic',
        'meglitinide',
        'meropenem',
        'mildew',
        'mold',
        'mould',
        'myroxylon pereirae',
        'nickel',
        'nuts',
        'oats',
        'over-the-counter drug',
        'oyster',
        'peanuts',
        'penicillin',
        'phosphodiesterase 5 inhibitor',
        'plant',
        'pollen',
        'pork',
        'potato',
        'pulse vegetables',
        'ragweed pollen',
        'raloxifene',
        'rapacuronium',
        'red meat',
        'remifentanil',
        'rubber',
        'rye',
        'scorpion venom',
        'seafood',
        'seed',
        'sevoflurane',
        'shrimp',
        'spider venom',
        'strawberries',
        'substance',
        'sufentanil',
        'sulfite based food preservative',
        'sulfonamide antibiotic',
        'sulfonamides',
        'sulphite based food preservative',
        'sulphonamide antibiotic',
        'sulphonamides',
        'sunlight',
        'teriparatide',
        'thiazolidinedione',
        'tomato',
        'tramadol',
        'tree pollen',
        'tree resin',
        'walnuts',
        'wasp venom',
        'watermelon',
        'weed pollen',
        'wheat',
        'wood',
        'wool'
    ],
    severities: ['Mild', 'Severe']
}
