<template>
  <b-dropdown :id="`table-row-options-${index}`" variant="link" no-caret :right="$store.state.appConfig.isRTL"
              dropleft boundary="window" :popper-opts="{ positionFixed: true }"
              :class="[{ show: tourProps.options.show && tourProps.options.index === index }, dropdownClass]" :toggle-class="toggleClass">
    <template #button-content>
      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
    </template>
    <b-dropdown-group :id="`table-row-options-group-${index}`" header="Options">
      <slot></slot>
    </b-dropdown-group>

  </b-dropdown>
</template>

<script>
  export default {
    name: 'TableRowOptions',
    props: {
      index: {
        type: Number,
        required: true
      },
      tourProps: {
        type: Object,
        default: () => ({
          options: {
            index: 0,
            show: false
          }
        })
      },
      dropdownClass: {
        type: String,
        default: ''
      },
      toggleClass: {
        type: String,
        default: ''
      },
    }
  }
</script>

<style>
  @media print {
    .table-row-options {
      display: none;
    }
  }
</style>
