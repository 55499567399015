import {createPopper} from '@popperjs/core';

export default {
    methods: {
        withPopper(dropdownList, component, {width}) {
            dropdownList.style.width = width

           /* if(component.value) {
                dropdownList.style.width = `${component.$el.offsetWidth + 30}px`;
            }
            else {
                dropdownList.style.width = width
            }*/

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'bottom-end',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({state}) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top-end', 'bottom-end'],
                        },
                    },
                ],
            });
            return () => popper.destroy()
        }
    }
}
