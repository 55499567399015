<template>
  <b-modal id="export-modal" centered size="xl" no-close-on-backdrop no-close-on-esc footer-class="d-block">
    <template #modal-header>
      <span class="h4 mb-0">
        Export to File
      </span>
      <div>
        <b-dropdown id="table-layout-options"
                    variant="link"
                    no-caret dropleft
                    :right="$store.state.appConfig.isRTL"
                    :disabled="state.exporting"
                    :toggle-class="['pr-0 btn-sm']">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
          </template>

          <b-dropdown-form form-class="px-1">
            <b-dropdown-item id="table-layout-option-help" @click="options.isDragView = !options.isDragView">
              <b-icon :icon="options.isDragView ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mr-50" />
              <span class="align-middle ml-50 align">Drag & Drop</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="$can('manage')" id="table-layout-option-help" @click="options.showExcludedFields = !options.showExcludedFields">
              <b-icon :icon="options.showExcludedFields ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mr-50" />
              <span class="align-middle ml-50">Hidden Fields</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <!-- Advanced Options -->
            <b-dropdown-item id="table-layout-option-help" @click="options.showAdvancedOptions = !options.showAdvancedOptions">
              <b-icon-sliders style="width: 14px; height: 14px" class="mr-50" />
              <span class="align-middle ml-50">Advanced Options</span>
            </b-dropdown-item>

            <template v-if="$can('manage', 'dev')">
              <b-dropdown-divider/>
              <b-dropdown-header>Developer</b-dropdown-header>
              <b-dropdown-item id="table-layout-option-help" @click="options.isDebug = !options.isDebug">
                <b-icon :icon="options.isDebug ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mr-50" />
                <span class="align-middle ml-50">Debug</span>
              </b-dropdown-item>
            </template>
          </b-dropdown-form>
        </b-dropdown>
      </div>
    </template>
    <template #default>
      <!-- No Data -->
      <template v-if="!exportableFields.length">
        <b-alert show variant="danger" class="mb-0">
          <p class="mb-0">No data available for export.</p>
          <p class="font-small-3">Make sure the table has finished loading before trying to export.</p>
        </b-alert>
      </template>
      <template v-else>
        <b-overlay :show="state.exporting" :opacity="1" rounded>
          <template #overlay>
            <b-card>
              <div class="d-flex justify-content-center mb-1">
                <b-spinner label="Loading..." variant="primary"></b-spinner>
              </div>
              <b-alert show variant="primary" class="mb-0">
                <div class="d-flex align-items-center mb-0">
                  <b-icon-cloud-download class="mr-50" size="16"></b-icon-cloud-download>
                  <b>{{ file.name }}</b>.{{ file.type.extension }}
                </div>
                <p class="font-small-3">Please wait while the file is being downloaded.</p>
              </b-alert>
            </b-card>
          </template>
          <template #default>
            <b-container>
              <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
                <form ref="form" class="p-50" @submit.stop.prevent="handleSubmit(exportToFile)">
                  <!-- File Name & Type -->
                  <b-row class="border rounded shadow p-50 mb-1">
                    <b-col class="col-12 col-xxl-6">
                      <validation-provider v-slot="validationContext" vid="file-name" name="File Name" rules="required">
                        <b-form-group label="File Name" label-for="file-name" label-class="pt-50" :invalid-feedback="validationContext.errors[0]">
                          <b-input id="file-name" v-model="file.name" :state="getValidationState(validationContext)" class="font-small-3"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-12 col-xxl-6">
                      <validation-provider v-slot="validationContext" vid="file-extension" name="File Extension" rules="required">
                        <b-form-group label="File Extension" label-for="file-extension" label-class="pt-50" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                          <v-select id="file-type"
                                    v-model="file.type"
                                    :options="options.types" label="extension"
                                    :reduce="option => option"
                                    :clearable="false"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    class="w-100 font-small-3"
                                    @input="onFileTypeSelect">
                            <template #option="{ label, extension }">
                              <div class="d-flex justify-content-between">
                                <span>{{ label }}</span>  <span>.{{ extension }}</span>
                              </div>
                            </template>
                            <template #selected-option="{ extension }">
                              <div class="d-flex justify-content-between">
                                <span>.{{ extension }}</span>
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Advanced Options -->
                    <template v-if="options.showAdvancedOptions">
                      <b-col class="col-12 col-xxl-4">
                        <b-form-group label="Delimiter" label-for="file-name">
                          <v-select id="file-type"
                                    v-model="file.delimiter"
                                    :options="options.delimiters"
                                    :reduce="option => option.value"
                                    :clearable="false"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    class="w-100 font-small-3">
                            <template #option="{ label, value }">
                              <div class="d-flex justify-content-between">
                                <span>{{ label }}</span>  <span>{{ value }}</span>
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col class="col-12 col-xxl-4">
                        <b-form-group label="Text Wrapper" label-for="file-type">
                          <v-select id="file-type"
                                    v-model="file.wrapper"
                                    :options="options.wrappers"
                                    :reduce="option => option.value"
                                    :clearable="false"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    class="w-100 font-small-3">
                            <template #option="{ label, value }">
                              <div class="d-flex justify-content-between">
                                <span>{{ label }}</span>  <span>{{ value }}</span>
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col class="col-12 col-xxl-4">
                          <b-form-group label="Include Header Row?" label-for="file-type">
                            <v-select id="file-type"
                                      v-model="file.includeHeader"
                                      :options="options.headers"
                                      :reduce="option => option.value"
                                      :clearable="false"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      class="w-100 font-small-3"/>
                          </b-form-group>
                        </b-col>
                      <hr class="mb-2"/>
                    </template>
                  </b-row>

                  <template v-if="file.delimiter === ',' && file.wrapper === ''">
                    <b-row>
                      <b-col cols="12" class="px-0">
                        <b-alert show variant="danger" class="font-small-3" dismissible>
                          <div>
                            You have selected <u>None</u> for the <u>Text Wrapper</u>. This may cause an issue if the value of a field contains the same value as the <u>Delimiter</u>.
                          </div>
                          <div>
                            Example: Values <code>John Doe, Jr</code> and <code>Albany High School</code> will be exported as <code>John Doe</code>, <code>Jr</code>, <code>Albany High School</code> creating an extra column.
                          </div>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </template>

                  <!-- File Fields -->
                  <b-row class="border rounded shadow p-50 mb-1">
                    <b-col>
                      <!-- Fields -->
                      <template>
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <label class="mb-0 mt-50 font-weight-bold" for="file-fields">File Fields</label>

                        </div>
                        <hr class="mb-2"/>


                        <!-- Debug View -->
                        <template v-if="$can('manage','dev') && options.isDebug">
                          <b-row>
                            <b-col>
                              <debug title="Available">{{ availableFields }}</debug>
                            </b-col>
                            <b-col>
                              <debug title="Selected">{{ file.fields }}</debug>
                            </b-col>
                            <b-col cols="12">
                              <debug title="Preview" code-language="csv">{{ buildCSV({ limit: 5 }) }}</debug>
                            </b-col>
                            <!--                      <b-col cols="12">
                                                    <debug title="Delimiter Check" code-language="csv">
                                                      {{
                                                        { delimites: checkForDelimiterErrors() }
                                                      }}
                                                    </debug>
                                                  </b-col>-->

                          </b-row>
                        </template>
                        <!-- Default View -->
                        <template v-else>
                          <!-- File Fields: Drag -->
                          <template v-if="options.isDragView">
                            <b-row class="fields-wrapper">
                              <b-col cols="6">
                                <div class="d-flex justify-content-between">
                                  <label>Available Fields ({{ availableFields.length }})</label>
                                  <b-button v-if="availableFields.length" size="sm" variant="link" class="p-0" @click="selectAll">Select All</b-button>
                                </div>

                                <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }" class="ps-export-fields border rounded">
                                  <draggable key="list-view" :list="availableFields" tag="ul"
                                             group="fields" handle=".handle"
                                             ghost-class="ghost"
                                             class="fields draggable-list-group list-group list-group-flush rounded">
                                    <b-list-group-item v-for="(field) in availableFields"
                                                       :id="field" :key="field"
                                                       tag="li" class="cursor-move handle font-small-3">
                                      <b-row>
                                        <b-col cols="auto" align-self="start">
                                          <feather-icon icon="MoreVerticalIcon" size="16"/>
                                          <feather-icon icon="MoreVerticalIcon" size="16" class="ml-n2"/>
                                        </b-col>
                                        <b-col align-self="start">
                                          {{ field }}
                                        </b-col>
                                      </b-row>
                                    </b-list-group-item>
                                  </draggable>
                                </vue-perfect-scrollbar>
                              </b-col>
                              <b-col cols="6">
                                <div class="d-flex justify-content-between">
                                  <label>Selected Fields ({{ file.fields.length }})</label>
                                  <b-button v-if="file.fields.length" size="sm" variant="link" class="p-0" @click="unselectAll">Unselect All</b-button>
                                </div>
                                <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }" class="ps-export-fields border rounded">
                                  <draggable key="list-view" :list="file.fields" tag="ul"
                                             group="fields" handle=".handle"
                                             ghost-class="ghost"
                                             class="fields draggable-list-group list-group list-group-flush rounded">
                                    <b-list-group-item v-for="(field) in file.fields"
                                                       :id="field" :key="field"
                                                       tag="li" class="cursor-move handle font-small-3">
                                      <b-row>
                                        <b-col cols="auto" align-self="start">
                                          <feather-icon icon="MoreVerticalIcon" size="16"/>
                                          <feather-icon icon="MoreVerticalIcon" size="16" class="ml-n2"/>
                                        </b-col>
                                        <b-col align-self="start">
                                          {{ field }}
                                        </b-col>
                                      </b-row>
                                    </b-list-group-item>
                                  </draggable>
                                </vue-perfect-scrollbar>
                              </b-col>
                            </b-row>
                          </template>
                          <!-- File Fields: Checkbox -->
                          <template v-else>
                            <validation-provider v-slot="validationContext" vid="file-fields" name="File Fields" rules="required">
                              <b-form-group :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                                <b-check-group id="file-fields"
                                               v-model="file.fields"
                                               :options="exportableFields"
                                               :state="getValidationState(validationContext)"
                                               @change="onFieldCheck" />
                              </b-form-group>
                            </validation-provider>
                          </template>
                        </template>
                      </template>
                    </b-col>
                  </b-row>

                  <!-- File Preview -->
                  <b-row v-if="options.showPreview" class="border rounded shadow p-50 mb-0">
                    <b-col>
                      <div class="d-flex justify-content-between align-items-center">
                        <label class="mb-0 mt-50 font-weight-bold" for="file-fields">File Preview ({{ file.type.extension }})</label>
                        <label v-if="file.fields.length" class="mb-0 mt-50 font-weight-bold" for="file-fields">
                          {{ file.items.length > options.previewLimit ? options.previewLimit : file.items.length }} of {{ file.items.length }}
                        </label>
                        <label v-else class="mb-0 mt-50 font-weight-bold" for="file-fields">
                          0 of {{ file.items.length }}
                        </label>
                      </div>
                      <vue-perfect-scrollbar :options="options.scrollbar" class="ps-export-preview">
                        <div class="csv-preview">
                          <prism :key="preview" :language="file.type.extension">{{ preview }}</prism>
                        </div>
                      </vue-perfect-scrollbar>
                    </b-col>
                  </b-row>
                </form>
              </validation-observer>
            </b-container>
          </template>
        </b-overlay>
      </template>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row >
        <b-col align-self="center" class="text-right px-0">
          <b-button type="reset" class="mr-1" :disabled="state.exporting" @click="$bvModal.hide(modal)">Close</b-button>
          <b-button type="submit" variant="primary" :disabled="state.exporting || !exportableFields.length" @click="$refs.observer.handleSubmit(exportToFile)">Export</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import flatten from 'flat'
import vSelect from 'vue-select';
import storageLocal from '@/mixins/storage.local.mixin';
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Prism from 'vue-prism-component';
import 'prismjs/components/prism-csv.min'
import 'prismjs/components/prism-json.min'

export default {
  name: 'ExportModal',
  components: {
    Prism,
    draggable,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ storageLocal ],
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsSortBy: {
      type: Function,
      required: false,
      default: (a, b) => a - b,
    },
    fileName: {
      type: String,
      required: false,
      default: null,
    },
    excludeFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      modal: 'export-modal',
      file: {
        name: this?.fileName ? this.fileName : this.defaultFileName(),
        type: { label: 'CSV', accept: 'text/csv', extension: 'csv' },
        delimiter: ',', // one character only
        wrapper: '"', // one character only
        includeHeader: true,
        fields: [],
        items: this.items,
      },
      options: {
        types: [
          { label: 'CSV', accept: 'text/csv', extension: 'csv' },
          { label: 'Text', accept: 'text/plaintext', extension: 'txt' },
          { label: 'JSON', accept: 'application/json', extension: 'json' }
        ],
        delimiters: [
          { label: 'Comma', value: ',' },
          { label: 'Semicolon', value: ';' },
          { label: 'Pipe', value: '|' },
          { label: 'Tab', value: '\t' },
        ],
        wrappers: [
          { label: 'Double Quote', value: '"' },
          { label: 'Single Quote', value: '\'' },
          { label: 'Backtick', value: '`' },
          { label: 'None', value: '' },
        ],
        headers: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        isDebug: false,
        isDragView: true,
        showAdvancedOptions: false,
        showExcludedFields: false,
        showPreview: true,
        previewLimit: 5,
        scrollbar: { maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }
      },
      state: {
        valid: false,
        disabled: false,
        exporting: false,
        snackbar: false
      }
    }
  },
  computed: {
    preview() {
      if(this.file.type.extension === 'json') {
        if(!this.file.fields.length) {
          return []
        }
        return this.buildJSON({ limit: this.options.previewLimit });
      }
      
      const rows = []
      const {delimiter, wrapper, includeHeader} = this.file

      /** Add Headers to Rows **/
      if(includeHeader) {
        rows.push(this.file.fields.map(field => `${wrapper}${field}${wrapper}`).join(`${delimiter}`))
      }

      let {items} = this.file
      items = items.slice(0, 5)

      /** Add Data to Rows **/
      items.forEach(item => {
        const row = []
        this.file.fields.forEach(field => {
          let value = _.get(item, field)
          const isWrapped = _.isString(value) || _.isArray(value)

          if(_.isArray(value)) {
            if(!_.isString(value[0])) {
              value = JSON.stringify(value)
            }
          }

          row.push( isWrapped ? `${wrapper}${value}${wrapper}` : value)
        })
        rows.push(row.join(`${delimiter}`))
      })
      return rows.join('\n')
      
      //return this.buildCSV({ limit: this.options.previewLimit })
    },
    delimiterErrors() {
      return this.checkForDelimiterErrors()
    },
    exportableFields() {
      let fields = (this.file.items && this.file.items[0]) ? Object.getOwnPropertyNames(flatten(this.file.items[0], { safe: true }) )/*.filter(prop => !prop.includes('items'))*/ : []

      // Special handling for fields like 'buses.students.items'. We want to show the length of the array, not the array itself
      fields.forEach((field, index) => {
        if (field.endsWith('.items')) {
          fields[index] = field.replace('.items', '.items.length');
        }
      });

      // If you have the permissions and options set to show all fields, return all fields
      if(this.$can('manage') && this.options.showExcludedFields ) {
        return fields
      }
      // Remove excluded fields
      fields = this.exclude(fields, this.excludeFields)
      return fields
    },
    availableFields() {
      return this.exportableFields.filter(field => !this.file.fields.includes(field))
    },
  },
  watch: {
    items() {
      this.file.items = this.items
    },
    fileName() {
      this.file.name = this.fileName
    },
  },
  mounted() {
    this.loadLocalSettings();
    this.file.fields = this.selectFields
  },
  methods: {
    defaultFileName() {
      if (this.$route.meta?.pageTitle) {
        const pageTitleWithHyphens = this.$route.meta.pageTitle.toLowerCase().replace(/\s+/g, '-');
        return `nyssma-${pageTitleWithHyphens}-${new Date().toISOString().slice(0, 10)}`;
      }
      return `nyssma-${this.$route.name}-${new Date().toISOString().slice(0, 10)}`;
    },
    loadLocalSettings() {
      if (this.canUseLocalSettings) {
        const item = this.$store.getters['storageLocal/getItem'](this.$route.name)
        this.file.type = item?.file?.type || this.file.type
        this.file.fields = item?.export?.fields || this.file.fields
      }
    },
    onFileTypeSelect() {
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'export.file',
        value: {
          type: this.file?.type
        }
      })
    },
    onFieldCheck() {
      this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'export.fields',
        value: this.file.fields
      })
    },
    selectAllOrNone() {
      if (this.file.fields.length === this.exportableFields.length) { this.unselectAll() }
      else { this.selectAll() }
    },
    selectAll() {
      this.file.fields = [...this.exportableFields];
    },
    unselectAll() {
      this.file.fields = [];
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async exportToFile(isActive = true) {
      this.state.disabled = true
      this.state.exporting = true
      if(this.file.name && this.file.fields.length) {
        this.state.valid = true
      }

      if(this.state.valid) {
        const data = this.file.type.extension === 'json' ? this.buildJSON() : this.buildCSV()

        /** Build File && Download **/
        const blob = new Blob([data], { type: `${this.file.type.accept};charset=utf-8;` });
        const downloadable = document.createElement('a');
        downloadable.href = URL.createObjectURL( blob );
        downloadable.download = `${this.file.name}.${this.file.type.extension}`;

        /** Close Modal **/
        setTimeout(() => {
          downloadable.click();
          setTimeout(() => URL.revokeObjectURL( downloadable.href ), 60000 );
          this.$bvModal.hide(this.modal);
          this.state.exporting = false
          this.state.disabled = false
          this.state.snackbar = true
          this.updateValue(isActive, false)
        }, 2000);
      }
      else {
        this.state.exporting = false
        this.state.disabled = false
        this.state.valid = false
        this.updateValue(isActive, true)
      }
    },
    
    buildJSON(options = { limit: 0 }) {
      let {items} = this.file
      if(options?.limit) {
        items = items.slice(0, options.limit)
      }

      return JSON.stringify(items.map(item => {
        const pickedItem = _.pick(item, this.file.fields);
        // Replace `undefined` with `null` for JSON serialization
        Object.keys(pickedItem).forEach(key => {
          if (pickedItem[key] === undefined || pickedItem[key] === '') {
            pickedItem[key] = null; // Or use '' for an empty string
          }
        });
        return pickedItem;
      }), null, 2);
    },

    buildCSV(options = { limit: 0 }) {
      const rows = []
      const {delimiter, wrapper, includeHeader} = this.file

      /** Add Headers to Rows **/
      if(includeHeader) {
        rows.push(this.file.fields.map(field => `${wrapper}${field}${wrapper}`).join(`${delimiter}`))
      }

      let {items} = this.file
      if(options?.limit) {
        items = items.slice(0, options.limit)
      }

      /** Add Data to Rows **/
      items.forEach(item => {
        const row = []
        this.file.fields.forEach(field => {
          let value = _.get(item, field)
          const isWrapped = _.isString(value) || _.isArray(value)

          if(_.isArray(value)) {
            if(!_.isString(value[0])) {
              value = JSON.stringify(value)
            }
          }

          row.push( isWrapped ? `${wrapper}${value}${wrapper}` : value)
        })
        rows.push(row.join(`${delimiter}`))
      })
      //console.log(rows)
      return rows.join('\n')
    },

    exclude(fields, excludedFields) {
      const patternRegexps = excludedFields.map(pattern =>
          new RegExp(`^${pattern.replace(/\./g, '\\.').replace(/\*/g, '.*')}$`));
      return fields.filter(field => !patternRegexps.some(patternRegexp => patternRegexp.test(field)));
    },

    updateValue(isActive, value) {
      //isActive.value = value
      //this.$emit('update:modelValue', false);
    },

    checkForDelimiterErrors() {
      //If the wrapper is anything other than empty, there will be no errors
      if(this.file.wrapper !== '') {
        return false
      }
      const csv = this.buildCSV()
      const {delimiter} = this.file;

      const rows = csv.split('\n');
      const counts = [];

      for (let i = 0; i < rows.length; i++) {
        let count = 0;
        const row = rows[i];

        for (let j = 0; j < row.length; j++) {
          if (row[j] === delimiter) {
            count++;
          }
        }

        counts.push(count);
      }
      return !counts.every((val, i, arr) => val === arr[0]);
    },
  }
}
</script>

<style scoped>
  .ps-export-fields {
    max-height: 35vh;
  }
  .ps-export-preview {
    max-height: 30vh;
  }
  .ghost {
    background: #f8f8f8;
  }
  .draggable-list-group .list-group-item:hover {
    background-color: #fff;
  }

  /*.draggable-list-group.list-group .list-group-item:hover {
    background-color: unset!important;
  }*/

  .list-group:empty,
  .list-group-row:empty,
  .list-group > div:empty {
    padding:1rem;
    text-align:left;
    border: 1px dashed rgba(34, 41, 47, 0.125);
    background: #fff;
    font-style: italic;
    font-size: 0.857rem;
  }

  .fields.list-group:empty:before,
  .fields.list-group-row:empty:before,
  .fields.list-group > div:empty:before {
    content: 'Empty...';
  }

  .fields-wrapper {
    height: 40vh;
  }

  .csv-preview > pre[class*='language-'] {
    background: #fff;
    border: 1px dashed #ebe9f1;
    border-radius: 0.5rem;
    padding: 1rem!important;
  }

  .csv-preview > pre[class*='language-'] > code {
    padding: 0!important;
  }
</style>
