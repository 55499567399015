<template>
  <b-modal id="import-modal" centered size="xl" no-close-on-backdrop footer-class="d-block" @close="onClose()">
    <template #modal-header>
      <span class="h4 mb-0">
        Import a File
      </span>
      <div>
        <b-dropdown id="table-layout-options"
                    variant="link"
                    no-caret dropleft
                    :disabled="file.importing"
                    :right="$store.state.appConfig.isRTL"
                    :toggle-class="['pr-0 btn-sm']">
          <template #button-content>
            <b-icon-three-dots-vertical style="width: 14px; height: 14px" class="align-middle text-body"/>
          </template>

          <b-dropdown-form>
            <b-dropdown-item v-if="$can('manage')" id="table-layout-option-help" @click="options.showPreview = !options.showPreview">
              <b-icon :icon="options.showExcludedFields ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mr-50" />
              <span class="align-middle ml-50">File Preview</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <!-- Advanced Options -->
            <b-dropdown-item id="table-layout-option-help" @click="options.showAdvancedOptions = !options.showAdvancedOptions">
              <b-icon-sliders style="width: 14px; height: 14px" class="mr-50" />
              <span class="align-middle ml-50">Advanced Options</span>
            </b-dropdown-item>
            <template v-if="$can('manage', 'dev')">
              <b-dropdown-divider/>
              <b-dropdown-header>Developer</b-dropdown-header>
              <b-dropdown-item id="table-layout-option-help" @click="options.isDebug = !options.isDebug">
                <b-icon :icon="options.isDebugs ? 'check-square' : 'square'" style="width: 14px; height: 14px" class="mr-50" />
                <span class="align-middle ml-50">Debug</span>
              </b-dropdown-item>
            </template>
          </b-dropdown-form>
        </b-dropdown>
      </div>
    </template>
    <template #default>
      <b-overlay :show="file.loading || file.importing" :opacity="1" rounded>
        <template #overlay>
          <b-card>
            <div class="d-flex justify-content-center mb-1">
              <b-spinner label="Loading..." variant="primary"></b-spinner>
            </div>
            <b-alert show variant="primary" class="mb-0">
              <div class="d-flex align-items-center mb-0">
                <b-icon-cloud-upload class="mr-50" size="16"></b-icon-cloud-upload>
                <b>Importing</b>
              </div>
              <p class="font-small-3">Please wait while the data is being updated.</p>
            </b-alert>
          </b-card>
        </template>
        <template #default>
          <b-container>
            <template v-if="!expectedFields.length">
              <b-alert show variant="danger" class="mb-0">
                <p class="mb-0">Not Configured</p>
                <p class="font-small-3">Required Fields Fields was empty.</p>
              </b-alert>
            </template>
            <template>
              <b-row class="border rounded shadow p-50 mb-1">
                <b-col class="col-12">
                  <b-form-group label="File" label-for="file-name" label-class="pt-50">
                    <b-input-group>
                      <b-form-file id="file-name" v-model="file.value" :disabled="file.loaded" accept=".csv, .txt, .json" class="font-small-3"/>
                      <b-input-group-append>
                        <b-button :disabled="!file.value || file.loaded" variant="primary" @click="loadFile()">Load</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col v-if="file.delimiter === ',' && file.wrapper === ''" cols="12" class="px-0">
                  <b-alert show variant="danger" class="font-small-3" dismissible>
                    <div>
                      You have selected <u>None</u> for the <u>Text Wrapper</u>. This may cause an issue if the value of a field contains the same value as the <u>Delimiter</u>.
                    </div>
                    <div>
                      Example: Values <code>John Doe, Jr</code> and <code>Albany High School</code> will be exported as <code>John Doe</code>, <code>Jr</code>, <code>Albany High School</code> creating an extra column.
                    </div>
                  </b-alert>
                </b-col>
                <!-- Advanced Options -->
                <template v-if="options.showAdvancedOptions">
                  <b-col class="col-12 col-xxl-4">
                    <b-form-group label="Delimiter" label-for="file-name">
                      <v-select id="file-type"
                                v-model="file.delimiter"
                                :options="options.delimiters"
                                :reduce="option => option.value"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3">
                        <template #option="{ label, value }">
                          <div class="d-flex justify-content-between">
                            <span>{{ label }}</span>  <span>{{ value }}</span>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12 col-xxl-4">
                    <b-form-group label="Text Wrapper" label-for="file-type">
                      <v-select id="file-type"
                                v-model="file.wrapper"
                                :options="options.wrappers"
                                :reduce="option => option.value"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3">
                        <template #option="{ label, value }">
                          <div class="d-flex justify-content-between">
                            <span>{{ label }}</span>  <span>{{ value }}</span>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12 col-xxl-4">
                    <b-form-group label="Include Header Row?" label-for="file-type">
                      <v-select id="file-type"
                                v-model="file.includeHeader"
                                :options="options.headers"
                                :reduce="option => option.value"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>

              <!-- File Preview -->
              <b-row v-if="options.showPreview && file.value && file.items.length" class="border rounded shadow p-50 mb-1">
                <b-col>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="mb-0 mt-50 font-weight-bold" for="file-fields">File Preview ({{ file.type.extension }})</label>
                    <label v-if="file.fields.length" class="mb-0 mt-50 font-weight-bold" for="file-fields">
                      {{ file.items.length > options.previewLimit ? options.previewLimit : file.items.length }} of {{ file.items.length }}
                    </label>
                    <label v-else class="mb-0 mt-50 font-weight-bold" for="file-fields">
                      {{ options.previewLimit }} of {{ file.items.length }}
                    </label>
                  </div>
                  <vue-perfect-scrollbar :options="options.scrollbar" class="ps-export-preview">
                    <div class="csv-preview">
                      <prism :language="file.type.extension">{{ preview }}</prism>
                    </div>
                  </vue-perfect-scrollbar>
                </b-col>
              </b-row>

              <!-- File Fields -->
              <b-row class="border rounded shadow p-50 mb-1">
                <b-col>
                  <!-- Header -->
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <label class="mb-0 mt-50 font-weight-bold" for="file-fields">File Fields</label>
                  </div>
                  <hr class="mb-2"/>

                  <!-- No Data -->
                  <template v-if="file.loaded">
                    <b-alert :show="hasMissingFields" variant="danger" class="mb-2">
                      <p class="mb-0">Missing Fields</p>
                      <p class="font-small-3">The selected file is missing expected fields. Importing has been disabled.</p>
                    </b-alert>
                    <b-alert v-if="!hasMissingFields" :show="hasUnknownFields" variant="warning" class="mb-2">
                      <p class="mb-0">Unknown Fields</p>
                      <p class="font-small-3">The selected file contains unknown fields. These fields will be ignored when importing.</p>
                    </b-alert>
                  </template>


                  <!-- Fields -->
                  <template>
                    <b-row class="fields-wrapper">
                      <b-col cols="6">
                        <div class="d-flex justify-content-between">
                          <label>Required Fields ({{ expectedFields.length }})</label>
                        </div>
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }" class="ps-export-fields border rounded">
                          <b-list-group flush class="fields rounded">
                            <b-list-group-item v-for="(field) in expectedFields" :key="field" class="font-small-3">
                              <div class="d-flex justify-content-between align-items-center">
                                <span>{{ field }}</span>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </vue-perfect-scrollbar>
                      </b-col>
                      <b-col cols="6">
                        <div class="d-flex justify-content-between">
                          <label>Loaded Fields ({{ loadedHeaders.length }})</label>
                        </div>
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }" class="ps-export-fields border rounded">
                          <b-list-group flush class="fields rounded">
                            <b-list-group-item v-for="(header) in loadedHeaders" :key="header.key" class="font-small-3">
                              <div class="d-flex justify-content-between">
                                <span>{{ header.key }}</span>
                                <b-badge :variant="getBadgeVariant(header)">{{ getBadgeText(header) }}</b-badge>
                              </div>

                            </b-list-group-item>
                          </b-list-group>
                        </vue-perfect-scrollbar>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
              </b-row>
            </template>
          </b-container>
        </template>
      </b-overlay>
    </template>

    <!-- Footer -->
    <template #modal-footer>

      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-button v-if="file.loaded" type="reset" class="mr-1" variant="danger" :disabled="file.importing" @click="clear()">Clear</b-button>
        </div>
        <div>
          <b-button type="reset" class="mr-1" :disabled="file.importing" @click="onClose">Close</b-button>
          <b-button type="submit" variant="primary" :disabled="file.importing || hasMissingFields || !file.loaded" @click="importFile()">Import</b-button>
        </div>
      </div>

<!--      <b-row>
        <b-col align-self="center" class="text-right px-0">
          <b-button v-if="file.loaded" type="reset" class="mr-1" variant="danger" :disabled="file.importing" @click="clear()">Clear</b-button>
          <b-button type="reset" class="mr-1" :disabled="file.importing" @click="onClose">Close</b-button>
          <b-button type="submit" variant="primary" :disabled="file.importing || hasMissingFields || !file.loaded" @click="importFile()">Import</b-button>
        </b-col>
      </b-row>-->
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { flatten, unflatten } from 'flat'
import vSelect from 'vue-select';
import storageLocal from '@/mixins/storage.local.mixin';
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Prism from 'vue-prism-component';
import 'prismjs/components/prism-csv.min'
import 'prismjs/components/prism-json.min'

export default {
  name: 'ImportModal',
  components: {
    Prism,
    draggable,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ storageLocal ],
  props: {
    expectedFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      modal: 'import-modal',
      file: {
        value: null,
        type: { label: 'CSV', accept: 'text/csv', extension: 'csv' },
        delimiter: ',', // one character only
        wrapper: '"', // one character only
        includeHeader: true,
        fields: [],
        headers: [],
        items: this.items,
        loading: false,
        loaded: false,
        importing: false
      },
      options: {
        types: [
          { label: 'CSV', accept: 'text/csv', extension: 'csv' },
          { label: 'Text', accept: 'text/plaintext', extension: 'txt' },
          { label: 'JSON', accept: 'application/json', extension: 'json' }
        ],
        delimiters: [
          { label: 'Comma', value: ',' },
          { label: 'Semicolon', value: ';' },
          { label: 'Pipe', value: '|' },
          { label: 'Tab', value: '\t' },
        ],
        wrappers: [
          { label: 'Double Quote', value: '"' },
          { label: 'Single Quote', value: '\'' },
          { label: 'Backtick', value: '`' },
          { label: 'None', value: '' },
        ],
        headers: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        isDebug: false,
        isDragView: true,
        showAdvancedOptions: false,
        showExcludedFields: false,
        showPreview: false,
        previewLimit: 5,
        scrollbar: { maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }
      },
      state: {
        valid: false,
        disabled: false,
        exporting: false,
        snackbar: false
      }
    }
  },
  computed: {
    preview() {
      const { items, type, delimiter, wrapper } = this.file
      if(!items.length) { return [] }

      if(type.extension === 'json') {
        const { previewLimit } = this.options
        return items.slice(0, previewLimit)
      }

      const headers = Object.keys(items[0])
      const rows = items.slice(1, 6).map(item => headers.map(header => {
        let value = item[header]
        const isWrapped = _.isString(value) || _.isArray(value)

        if(_.isArray(value)) {
          if(!_.isString(value[0])) {
            value = JSON.stringify(value)
          }
        }
        return isWrapped ? `${wrapper}${value}${wrapper}` : value
      }).join(`${delimiter}`));

      //start of row add header
      rows.unshift(headers.map(header => `${wrapper}${header}${wrapper}`).join(`${delimiter}`))
      return rows.join('\n');
    },
    loadedHeaders() {
      if(!this.file.loaded || !this.file.headers.length) { return [] }
      // Initialize an empty array to store the header objects
      const headers = [];

      // Get the headers from the file, if available
      const fileHeaders = this.file?.headers || [];

      // Create a map of the file headers for quick lookup
      const fileHeadersSet = new Set(fileHeaders);

      // First, process each expected field
      this.expectedFields.forEach(expectedField => {
        headers.push({
          key: expectedField,
          isExpected: true,
          isMissing: !fileHeadersSet.has(expectedField)
        });
      });

      // Optionally, add headers that are present in the file but not expected
      fileHeaders.forEach(header => {
        if (!this.expectedFields.includes(header)) {
          headers.push({
            key: header,
            isExpected: false,
            isMissing: false
          });
        }
      });

      return headers;
    },
    hasMissingFields() {
      return this.loadedHeaders.some(header => header.isMissing)
    },
    hasUnknownFields() {
      return this.loadedHeaders.some(header => !header.isExpected)
    }
  },
  watch: {
    items() {
      this.file.items = this.items
    },
  },
  methods: {
    loadFile() {
      const { value } = this.file;
      const { types } = this.options;
      const type = types.find(t => t.accept === value.type || t.accept === value.name.split('.').pop().toLowerCase());
      if(type) {
        this.file.type = type;
      }

      this.file.loading = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        switch(type.extension) {
          case 'csv': {
            this.file.items = this.parseCSV(data)
            this.file.headers = (this.file.items && this.file.items[0]) ? Object.getOwnPropertyNames(flatten(this.file.items[0], { safe: true })) : []
            break;
          }
          case 'json': {
            this.file.items = JSON.parse(data)
            this.file.headers = (this.file.items && this.file.items[0]) ? Object.getOwnPropertyNames(flatten(this.file.items[0], { safe: true })) : []
            break;
          }
          default: break;
        }
        this.file.loaded = true;
        this.file.loading = false;
      };
      reader.readAsText(this.file.value);
    },

    parseCSV(data) {
      const rows = data.split('\n');
      const headers = rows.shift().split(this.file.delimiter).map(header => header.replace(new RegExp(this.file.wrapper, 'g'), '').trim());
      return rows.map(row => {
        const values = row.split(this.file.delimiter);
        return headers.reduce((acc, header, index) => {
          acc[header] = values[index]?.replace(new RegExp(this.file.wrapper, 'g'), '')?.trim() ?? '';
          return acc;
        }, {});
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async importFile() {
      this.file.importing = true
      await new Promise(resolve => setTimeout(() => {
        if(this.file.type.extension === 'json') {
          this.$emit('import', this.file?.items ?? [])
        }
        else {
          this.$emit('import', this.file?.items?.map(item => unflatten(item)) ?? [])
        }
        resolve()
      }, 2000))

      this.file.importing = false
      this.onClose()
    },

    exclude(fields, excludedFields) {
      const patternRegexps = excludedFields.map(pattern =>
          new RegExp(`^${pattern.replace(/\./g, '\\.').replace(/\*/g, '.*')}$`));
      return fields.filter(field => !patternRegexps.some(patternRegexp => patternRegexp.test(field)));
    },

    onClose() {
      this.$bvModal.hide(this.modal)
      this.clear()
    },

    clear() {
      this.file.value = null
      this.file.headers = []
      this.file.items = []
      this.file.type = null
      this.file.loaded = false
      this.file.loading = false
      this.file.importing = false
    },

    getBadgeVariant(header) {
      if(header.isExpected && !header.isMissing) return 'success'
      if(header.isExpected && header.isMissing) return 'danger'
      if(!header.isExpected) return 'warning'
      return 'dark'
    },

    getBadgeText(header) {
      if(header.isExpected && !header.isMissing) return 'Match'
      if(header.isExpected && header.isMissing) return 'Missing'
      if(!header.isExpected) return 'Unknown'
      return 'warning'
    }
  }
}
</script>

<style scoped>
  .ps-export-fields {
    max-height: 35vh;
  }
  .ps-export-preview {
    max-height: 30vh;
  }

  #import-modal .modal-header {
    align-items: center;
  }


  .ghost {
    background: #f8f8f8;
  }
  .draggable-list-group .list-group-item:hover {
    background-color: #fff;
  }

  /*.draggable-list-group.list-group .list-group-item:hover {
    background-color: unset!important;
  }*/

  .list-group:empty,
  .list-group-row:empty,
  .list-group > div:empty {
    padding:1rem;
    text-align:left;
    border: 1px dashed rgba(34, 41, 47, 0.125);
    background: #fff;
    font-style: italic;
    font-size: 0.857rem;
  }

  .fields.list-group:empty:before,
  .fields.list-group-row:empty:before,
  .fields.list-group > div:empty:before {
    content: 'Empty...';
  }

  .fields-wrapper {
    height: 40vh;
  }

  .csv-preview > pre[class*='language-'] {
    background: #fff;
    border: 1px dashed #ebe9f1;
    border-radius: 0.5rem;
    padding: 1rem!important;
  }

  .csv-preview > pre[class*='language-'] > code {
    padding: 0!important;
  }
</style>
