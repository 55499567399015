// eslint-disable-next-line import/prefer-default-export

export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                code
                parts
                instruments {
                    items {
                        instrument {
                            id
                        }
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                order
            }
            nextToken
        }
    }
`;

export const listSelections = /* GraphQL */ `
    query ListSelections(
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    student {
                        id
                        name {
                            first
                            last
                        }
                        sex
                        gender
                        grade
                        phone {
                            number
                        }
                        school {
                            name {
                                legal
                            }
                        }
                        event {
                            id
                            busId
                            chaperoneId
                            hotelId
                            room
                        }
                    }
                    instrument {
                        id
                        name
                        order
                    }
                    festival {
                        name
                    }
                    applicationInstrumentId
                    applicationSelectionId
                }
                state {
                    order
                }
                createdAt
                updatedAt
                selectionEnsembleId
            }
            nextToken
        }
    }
`;

export const getStudentEvent = /* GraphQL */ `
    query GetStudentEvent($id: ID!) {
        getStudentEvent(id: $id) {
            id
            studentId
            busId
            chaperoneId
            hotelId
            room
        }
    }
`;

export const onCreateStudentEvent = /* GraphQL */ `
    subscription OnCreateStudentEvent(
        $filter: ModelSubscriptionStudentEventFilterInput
    ) {
        onCreateStudentEvent(filter: $filter) {
            id
            studentId
            busId
            chaperoneId
            hotelId
            room
            checkin {
                thursday
                friday
                saturday
            }
            signature
            medicalReceived
        }
    }
`;
export const onUpdateStudentEvent = /* GraphQL */ `
    subscription OnUpdateStudentEvent(
        $filter: ModelSubscriptionStudentEventFilterInput
    ) {
        onUpdateStudentEvent(filter: $filter) {
            id
            studentId
            busId
            chaperoneId
            hotelId
            room
            checkin {
                thursday
                friday
                saturday
            }
            signature
            medicalReceived
        }
    }
`;
export const onDeleteStudentEvent = /* GraphQL */ `
    subscription OnDeleteStudentEvent(
        $filter: ModelSubscriptionStudentEventFilterInput
    ) {
        onDeleteStudentEvent(filter: $filter) {
            id
        }
    }
`;
