import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
    computed: {
        isObject(item) {
            return item?.constructor?.name === 'Object'
        }
    },
    methods: {
        canUseLocalSettings() {
            const routes = this.$store.getters['storageLocal/getItem']('routes')
            if(routes) {
                const route = routes.find(r => r.name === this.$route.name)
                return route?.enabled && route?.isLocalEnabled
            }
            return false
        },
    }
}
