import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    methods: {
        notify(options) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: options.title,
                    icon: options.icon || '',
                    text: options.text,
                    variant: options.variant,
                },
            },
            { position: options?.position || 'bottom-right' })
        },
        syncNotification(options) {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon: options?.icon || 'fa-solid fa-sync fa-spin',
                        text: options?.text || 'Syncing...',
                        variant: options?.variant || 'primary',
                    },
                },
                { position: options?.position || 'bottom-right' })
        },
    },
}
