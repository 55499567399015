import _ from 'lodash';

export default {
    data() {
        return {
           scrollbarSettings: { maxScrollbarLength: 150, wheelPropagation: false, suppressScrollX: true }
        }
    },
    methods: {
        getSexIcon(student) {
            if(student?.sex?.toLowerCase() === 'male') {
                return 'fa-solid fa-person'
            }
            if(student?.sex?.toLowerCase() === 'female') {
                return 'fa-solid fa-person-dress'
            }
            return 'fa-solid fa-person-half-dress'
        },
        getSexBadge(student) {
            if(student.sex?.toLowerCase() === 'male') {
                return 'M'
            }
            if(student.sex?.toLowerCase() === 'female') {
                return 'F'
            }
            return student.sex ? 'X' : '?'
        },
        getSexBadgeVariant(student) {
            if(student?.sex?.toLowerCase() === 'male') {
                return 'primary'
            }
            if(student?.sex?.toLowerCase() === 'female') {
                return 'primary'
            }
            return student?.sex ? 'secondary' : 'danger'
        },
        getCheckinVariant(value) {
            if(value === null) { return 'light-primary' }
            return value === true ? 'light-success' : 'light-danger'
        },
        getCheckinIcon(value) {
            if(value === null) { return 'fa-calendar' }
            return value === true ? 'fa-calendar-check' : 'fa-calendar-xmark'
        },
        getFullName(objWithName, options = { lnf: false }) {
            let response;
            if(!objWithName?.name) { response = null }
            if(options.lnf) { response = `${objWithName?.name?.last}, ${objWithName?.name?.first}` }
            else {
                response = `${objWithName?.name?.first} ${objWithName?.name?.last}`
            }
            return _.isEmpty(response?.trim() ?? '') ? null : response
        },
        hasBusNote(notes) {
          return notes?.filter(note => note.type === 'bus')?.length > 0 ?? false
        },
        getApplicationWithSelection(applications) {
            return applications.items.find(application => application.selection?.accepted)
        },
        sortItems(sorting, items) {
            const { by, desc } = sorting;
            const order = desc ? 'desc' : 'asc';
            const orders = [];
            for (let i = 0; i < by.length; i++) {
                orders.push(order);
            }
            return _.orderBy(items, by, orders);
        },
    }
}
