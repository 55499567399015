import {uuid} from 'vue-uuid';
import {API, graphqlOperation} from 'aws-amplify';
import store from '@/store';
import {batchProcess} from '@/graphql/cascade/batch';

/** Classes **/
class Model {
    constructor(options) {
        this.id = ((options?.id !== undefined) ? options.id : uuid.v4())
        //this.createdAt = '2023-04-08T18:04:48.787Z'; //todo - Remove line. Need for testing
    }
}

export class StudentEvent extends Model {
    constructor(options) {
        super(options);
        this.studentId = options.studentId ?? null;
        this.busId = options.busId ?? null;
        this.chaperoneId = options.chaperoneId ?? null;
        this.hotelId = options.hotelId ?? null;
        this.room = options.room ?? null;
        this.checkin = {
            thursday: options.checkin?.thursday ?? null,
            friday: options.checkin?.friday ?? null,
            saturday: options.checkin?.saturday ?? null
        }
        this.medicalReceived = options.medicalReceived ?? false;
    }
}

export class Chaperone extends Model {
    constructor() {
        super();
        this.name = {
            prefix: null,
            first: '',
            last: ''
        };
        this.email = {
            address: null,
            type: null
        };
        this.phone = {
            number: null,
            type: null
        };
        this.role = 'Chaperone';
        this.hotelId = null;
        this.room = null;
    }
}

export class Hotel extends Model {
    constructor() {
        super();
        this.name = '';
        this.phone = {
            number: null
        }
        this.medicalSuite = null;
    }
}

export class Bus extends Model {
    constructor() {
        super();
        this.name = ''
        this.capacity = 0
    }
}

/** Util Classes **/
export class Room {
    constructor(number, occupants) {
        this.number = number;
        this.occupants = occupants;
    }
}

export class Occupant {
    constructor(type, value) {
        this.type = type;
        this.id = value.id;
        this.name = {
            first: value.name?.first,
            last: value.name?.last
        }
        this.title = type === 'chaperone' ? value.role : value.school?.name?.legal;
        this.raw = value;
    }
}

export class RegistrationGroup {
    constructor(from, to) {
        this.id = uuid.v4();
        this.from = from;
        this.to = to;
        this.name = `${from}-${to}`;
    }
}

export class EventStudent {
    constructor(student) {
        this.id = student.id;
        this.name = student.name;
        this.sex = student.sex;
        this.gender = student.gender;
        this.applications = {
            items: [
                {
                    id: student.selection.application.id,
                    instrument: student.selection.instrument,
                    selection: {
                        id: student.selection.id,
                        ensemble: student.selection.ensemble,
                        part: student.selection.part,
                        accepted: student.selection.accepted,
                    }
                }
            ]
        }
    }
}





/** Functions **/
export async function getSettings(key) {
    const response = await API.graphql(graphqlOperation(getSetting, { key: key }));
    const data = response.data.getSetting
    if(data) {
        return JSON.parse(data.value)
    }
    return { }
}




/* eslint-disable no-await-in-loop */
export async function getBuses() {
    const buses = [];
    const current = store.getters['settings/getCurrent'];
    let nextToken = null;

    try {
        do {
            const response = await API.graphql(graphqlOperation(listBusesQuery, {
                filter: {
                    createdAt: { between: [current.year.start, current.year.end] }
                },
                limit: 500,
                nextToken: nextToken
            }));
            buses.push(...response.data.listBuses.items);
            nextToken = response.data.listBuses.nextToken;
        }
        while (nextToken);

        buses.sort((a, b) => a.name.localeCompare(b.name));
        return buses;
    }
    catch (error) {
        console.error('listBuses', error);
        return [];
    }
}

export async function createBus(bus) {
    let response;
    if(!bus) {
        response = await API.graphql(graphqlOperation(createBusQuery, { input: new Bus() }));
    }
    else {
        if(!bus.id) bus.id = uuid.v4();
        response = await API.graphql(graphqlOperation(createBusQuery, { input: bus }));
    }
    return response.data.createBus;
}

export async function updateBus(bus) {
    const response = await API.graphql(graphqlOperation(updateBusQuery, { input: bus }));
    return response.data.updateBus;
}

export async function deleteBus(bus) {
    const events = []
    const response = await API.graphql(graphqlOperation(deleteBusQuery, { input: { id: bus.id } }));
    await getStudentEventsByBusId(bus.id).then((studentEvents) => {
        events.push(...studentEvents)
    })
    for await (const event of events) {
        await updateStudentEvent({ id: event.id, busId: null })
    }
    return response.data.deleteBus
}

/** Hotels **/
export async function createHotel(hotel) {
    let response;
    if(!hotel) {
        response = await API.graphql(graphqlOperation(createHotelQuery, { input: new Hotel() }));
    }
    else {
        if(!hotel.id) hotel.id = uuid.v4();
        response = await API.graphql(graphqlOperation(createHotelQuery, { input: hotel }));
    }
    return response.data.createHotel;
}

export async function updateHotel(hotel) {
    const response = await API.graphql(graphqlOperation(updateHotelQuery, { input: hotel }));
    return response.data.updateHotel;
}

export async function deleteHotel(hotel) {
    const response = await API.graphql(graphqlOperation(deleteHotelQuery, { input: { id: hotel.id } }));
    return response.data.deleteHotel
}

export async function getHotels() {
    const hotels = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const response = await API.graphql(graphqlOperation(listHotelsQuery, {
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }));
        hotels.push(...response.data.listHotels.items);
        nextToken = response.data.listHotels.nextToken;
    }
    while (nextToken);

    hotels.sort((a, b) => a.name.localeCompare(b.name));
    return hotels;
}

/** Chaperones **/
export async function createChaperone(chaperone) {
    let response;
    if(!chaperone) {
        response = await API.graphql(graphqlOperation(createChaperoneQuery, { input: new Chaperone() }));
    }
    else {
        if(!chaperone.id) chaperone.id = uuid.v4();
         response = await API.graphql(graphqlOperation(createChaperoneQuery, { input: chaperone }));
    }
    return response.data.createChaperone;
}

export async function updateChaperone(chaperone) {
    const response = await API.graphql(graphqlOperation(updateChaperoneQuery, { input: chaperone }));
    return response.data.updateChaperone;
}

export async function deleteChaperone(chaperone) {
    const response = await API.graphql(graphqlOperation(deleteChaperoneQuery, { input: { id: chaperone.id } }));
    return response.data.deleteChaperone
}

export async function getChaperones(queryOptions) {
    const chaperones = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const input = {
            includeHotel: queryOptions?.includeHotel ?? false,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }
        const response = await API.graphql(graphqlOperation(listChaperonesQuery, input));
        chaperones.push(...response.data.listChaperones.items);
        nextToken = response.data.listChaperones.nextToken;
    }
    while (nextToken);

    chaperones.sort((a, b) => a.name?.first?.localeCompare(b.name?.first) || a.name?.last?.localeCompare(b.name?.last));
    return chaperones;
}

export async function getChaperonesByHotelId(hotelId) {
    const chaperones = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const response = await API.graphql(graphqlOperation(listChaperonesByHotelQuery, {
            hotelId: hotelId,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }));
        chaperones.push(...response.data.listChaperonesByHotel.items);
        nextToken = response.data.listChaperonesByHotel.nextToken;
    }
    while (nextToken);

    chaperones.sort((a, b) => a.name?.first?.localeCompare(b.name?.first) || a.name?.last?.localeCompare(b.name?.last));
    return chaperones;
}

/** Students **/
export async function getStudentsFromSelections() {
    const selections = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const response = await API.graphql(graphqlOperation(listSelectionsQuery, {
            filter: {
                accepted: { eq: true },
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }));
        selections.push(...response.data.listSelections.items);
        nextToken = response.data.listSelections.nextToken;
    }
    while (nextToken);

    const students = [];
    students.push(...selections.map(selection => mappedStudentFromSelection(selection)));
    
    students.sort((a, b) =>
        a.name?.first?.localeCompare(b.name?.first)
        || a.name?.last?.localeCompare(b.name?.last))

    return students;
}

export function mappedStudentFromSelection(selection) {
    return {
        id: selection.application.student.id,
        name: selection.application.student.name,
        dob: selection.application.student.dob,
        sex: selection.application.student.sex,
        gender: selection.application.student.gender,
        grade: selection.application.student.grade,
        school: selection.application.student.school,
        address: selection.application.student.address,
        email: selection.application.student.email,
        phone: selection.application.student.phone,
        selection: {
            id: selection.id,
            ensemble: selection.ensemble,
            part: selection.part,
            accepted: selection.accepted,
            instrument: selection.application.instrument,
            application: {
                id: selection.application.id,
                festival: selection.application.festival,
            },
            updatedAt: selection.updatedAt
        },
        eventId: selection.application.student.eventId,
        event: selection.application.student.event
    }
}

export async function updateStudent(student) {
    const studentInput = {
        id: student.id,
        name: student.name,
        sex: student.sex,
        gender: student.gender,
        phone: student.phone,
        email: student.email,
        eventId: student.eventId
    }
    const studentEventInput = student.event

    await API.graphql(graphqlOperation(updateStudentQuery, { input: studentInput }));
    await API.graphql(graphqlOperation(updateStudentEventQuery, { input: studentEventInput }));
}

/** Student Events **/
export async function getStudentEvents(queryOptions) {
    const students = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const input = {
            includeStudentSchool: queryOptions?.includeStudentSchool ?? false,
            includeNotes: queryOptions?.includeNotes ?? false,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }
        if(queryOptions) Object.assign(input, queryOptions);
        const response = await API.graphql(graphqlOperation(listStudentEventsQuery, input));
        students.push(...response.data.listStudentEvents.items);
        nextToken = response.data.listStudentEvents.nextToken;
    }
    while (nextToken);
    for(const student of students) {
        student.student.applications.items = student.student.applications.items.filter(application => application?.selection?.accepted === true);
    }
    return students;
}

export async function getStudentEventsByBusId(busId, queryOptions) {
    const students = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const response = await API.graphql(graphqlOperation(listStudentEventsByBusQuery, {
            busId: busId,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }));
        students.push(...response.data.listStudentEventsByBus.items);
        nextToken = response.data.listStudentEventsByBus.nextToken;
    }
    while (nextToken);
    for(const student of students) {
        student.student.applications.items = student.student.applications.items.filter(application => application?.selection?.accepted === true);
    }
    return students;
}

export async function getStudentEventsByChaperoneId(chaperoneId, queryOptions) {
    const students = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const input = {
            chaperoneId: chaperoneId,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }
        if(queryOptions) Object.assign(input, queryOptions);
        const response = await API.graphql(graphqlOperation(listStudentEventsByChaperoneQuery, input));
        students.push(...response.data.listStudentEventsByChaperone.items);
        nextToken = response.data.listStudentEventsByChaperone.nextToken;
    }
    while (nextToken);
    for(const student of students) {
        student.student.applications.items = student.student.applications.items.filter(application => application?.selection?.accepted === true);
    }
    return students;
}

export async function getStudentEventsByHotelId(hotelId, queryOptions) {
    const students = [];
    const current = store.getters['settings/getCurrent'];

    let nextToken = null;
    do {
        const response = await API.graphql(graphqlOperation(listStudentEventsByHotelQuery, {
            hotelId: hotelId,
            includeSignature: queryOptions?.includeSignature ?? false,
            filter: {
                createdAt: { between: [current.year.start, current.year.end] }
            },
            limit: 500,
            nextToken: nextToken
        }));
        students.push(...response.data.listStudentEventsByHotel.items);
        nextToken = response.data.listStudentEventsByHotel.nextToken;
    }
    while (nextToken);
    for(const student of students) {
        student.student.applications.items = student.student.applications.items.filter(application => application?.selection?.accepted === true);
    }
    return students;
}

export async function createStudentEvent(studentEvent) {
    if(!studentEvent) {
       throw new Error('Student Event is required');
    }
    else {
        const event = new StudentEvent({
            id: studentEvent.id,
            studentId: studentEvent.studentId,
            busId: studentEvent.busId,
            chaperoneId: studentEvent.chaperoneId,
            hotelId: studentEvent.hotelId,
            room: studentEvent.room
        });
        const response = await API.graphql(graphqlOperation(createStudentEventQuery, { input: event }));
        await API.graphql(graphqlOperation(updateStudentQuery, { input: { id: event.studentId, eventId: event.id } }));
        return response.data.createStudentEvent;
    }
}

export async function updateStudentEvent(studentEvent) {
    const response = await API.graphql(graphqlOperation(updateStudentEventQuery, { input: studentEvent }));
    return response.data.updateStudentEvent;
}

export async function deleteStudentEvent(studentEvent) {
    await API.graphql(graphqlOperation(updateStudentQuery, { input: { id: studentEvent.studentId, eventId: null } }));
    const response = await API.graphql(graphqlOperation(deleteStudentEventQuery, { input: { id: studentEvent.id } }));
    return response.data.deleteChaperone
}

export async function deleteStudentEvents(studentEvents) {
    const deletedEvents = [];
    try {
        await batchProcess(studentEvents, async (studentEvent) => {
            const result = await deleteStudentEvent(studentEvent);
            deletedEvents.push(result);
        }, null, { batchSize: 20, batchDelay: 1000 });

        return deletedEvents;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
}


/** Queries **/
const listBusesQuery = /* GraphQL */ `
    query ListBuses(
        $id: ID
        $filter: ModelBusFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listBuses(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                capacity
            }
            nextToken
        }
    }
`;
const createBusQuery = /* GraphQL */ `
    mutation CreateBus(
        $input: CreateBusInput!
        $condition: ModelBusConditionInput
    ) {
        createBus(input: $input, condition: $condition) {
            id
            name
            capacity
            students {
                items {
                    id
                    dob
                    sex
                    gender
                    grade
                    schoolID
                    eventId
                    createdAt
                    updatedAt
                    busStudentsId
                    chaperoneStudentsId
                    hotelStudentsId
                    studentInvoiceId
                    __typename
                }
                nextToken
                __typename
            }
            createdAt
            updatedAt
            __typename
        }
    }
`;
const updateBusQuery = /* GraphQL */ `
    mutation UpdateBus(
        $input: UpdateBusInput!
        $condition: ModelBusConditionInput
    ) {
        updateBus(input: $input, condition: $condition) {
            id
            name
            capacity
            students {
                items {
                    id
                    dob
                    sex
                    gender
                    grade
                    schoolID
                    eventId
                    createdAt
                    updatedAt
                    busStudentsId
                    chaperoneStudentsId
                    hotelStudentsId
                    studentInvoiceId
                    __typename
                }
                nextToken
                __typename
            }
            createdAt
            updatedAt
            __typename
        }
    }
`;
const deleteBusQuery = /* GraphQL */ `
    mutation DeleteBus(
        $input: DeleteBusInput!
        $condition: ModelBusConditionInput
    ) {
        deleteBus(input: $input, condition: $condition) {
            id
            name
            capacity
            students {
                items {
                    id
                    dob
                    sex
                    gender
                    grade
                    schoolID
                    eventId
                    createdAt
                    updatedAt
                    busStudentsId
                    chaperoneStudentsId
                    hotelStudentsId
                    studentInvoiceId
                    __typename
                }
                nextToken
                __typename
            }
            createdAt
            updatedAt
            __typename
        }
    }
`;


const listHotelsQuery = /* GraphQL */ `
    query ListHotels(
        $id: ID
        $filter: ModelHotelFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listHotels(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                phone {
                    number
                }
                medicalSuite
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
const createHotelQuery = /* GraphQL */ `
    mutation CreateHotel(
        $input: CreateHotelInput!
        $condition: ModelHotelConditionInput
    ) {
        createHotel(input: $input, condition: $condition) {
            id
            name
            phone {
                number
            }
            createdAt
            updatedAt
        }
    }
`;
const updateHotelQuery = /* GraphQL */ `
    mutation UpdateHotel(
        $input: UpdateHotelInput!
        $condition: ModelHotelConditionInput
    ) {
        updateHotel(input: $input, condition: $condition) {
            id
            name
            phone {
                number
            }
            createdAt
            updatedAt
        }
    }
`;
const deleteHotelQuery = /* GraphQL */ `
    mutation DeleteHotel(
        $input: DeleteHotelInput!
        $condition: ModelHotelConditionInput
    ) {
        deleteHotel(input: $input, condition: $condition) {
            id
        }
    }
`;

const listChaperonesQuery = /* GraphQL */ `
    query ListChaperones(
        $id: ID
        $filter: ModelChaperoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
        $includeHotel: Boolean!
    ) {
        listChaperones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    prefix
                    first
                    last
                }
                phone {
                    number
                }
                email {
                    address
                }
                role
                room
                hotelId
                hotel @include(if: $includeHotel) {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
const listChaperonesByHotelQuery = /* GraphQL */ `
    query ListChaperonesByHotel(
        $hotelId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelChaperoneFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listChaperonesByHotel(
            hotelId: $hotelId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name {
                    prefix
                    first
                    last
                }
                phone {
                    number
                }
                email {
                    address
                }
                role
                room
                hotelId
            }
            nextToken
        }
    }
`;
const createChaperoneQuery = /* GraphQL */ `
    mutation CreateChaperone(
        $input: CreateChaperoneInput!
        $condition: ModelChaperoneConditionInput
    ) {
        createChaperone(input: $input, condition: $condition) {
            id
            name {
                prefix
                first
                last
            }
            phone {
                number
            }
            email {
                address
            }
            role
            room
            hotelId
            createdAt
            updatedAt
        }
    }
`;
const updateChaperoneQuery = /* GraphQL */ `
    mutation UpdateChaperone(
        $input: UpdateChaperoneInput!
        $condition: ModelChaperoneConditionInput
    ) {
        updateChaperone(input: $input, condition: $condition) {
            id
            name {
                prefix
                first
                last
            }
            phone {
                number
            }
            email {
                address
            }
            role
            room
            hotelId
            createdAt
            updatedAt
        }
    }
`;
const deleteChaperoneQuery = /* GraphQL */ `
    mutation DeleteChaperone(
        $input: DeleteChaperoneInput!
        $condition: ModelChaperoneConditionInput
    ) {
        deleteChaperone(input: $input, condition: $condition) {
            id
        }
    }
`;

const listStudentEventsQuery = /* GraphQL */ `
    query ListStudentEvents(
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
        $includeStudentSchool: Boolean!
        $includeNotes: Boolean!
    ) {
        listStudentEvents(
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                studentId
                busId
                chaperoneId
                hotelId
                room
                notes @include(if: $includeNotes) {
                    id
                    type
                    text
                }
                checkin {
                    thursday
                    friday
                    saturday
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    sex
                    gender
                    school @include(if: $includeStudentSchool) {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            instrument {
                                id
                                name
                            }
                            selection {
                                id
                                ensemble {
                                    id
                                    name
                                }
                                part
                                accepted
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
const listStudentEventsByBusQuery = /* GraphQL */ `
    query ListStudentEventsByBus(
        $busId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentEventsByBus(
            busId: $busId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                studentId
                busId
                chaperoneId
                hotelId
                room
                student {
                    id
                    name {
                        first
                        last
                    }
                    sex
                    gender
                    applications {
                        items {
                            id
                            instrument {
                                id
                                name
                            }
                            selection {
                                id
                                ensemble {
                                    id
                                    name
                                }
                                part
                                accepted
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
const listStudentEventsByChaperoneQuery = /* GraphQL */ `
    query ListStudentEventsByChaperone(
        $chaperoneId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
#        $includeChaperone: Boolean = false
    ) {
        listStudentEventsByChaperone(
            chaperoneId: $chaperoneId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                studentId
                busId
                chaperoneId
                hotelId
                room
                checkin {
                    thursday
                    friday
                    saturday
                }
                student {
                    id
                    name {
                        first
                        last
                    } 
                    sex
                    gender
                    applications {
                        items {
                            id
                            instrument {
                                id
                                name
                            }
                            selection {
                                id
                                ensemble {
                                    id
                                    name
                                }
                                part
                                accepted
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
const listStudentEventsByHotelQuery = /* GraphQL */ `
    query ListStudentEventsByHotel(
        $hotelId: ID!
        $includeSignature: Boolean!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentEventsByHotel(
            hotelId: $hotelId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                studentId
                busId
                chaperoneId
                hotelId
                room
                signature @include(if: $includeSignature)
                student {
                    id
                    name {
                        first
                        last
                    }
                    sex
                    gender
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            instrument {
                                id
                                name
                            }
                            selection {
                                id
                                ensemble {
                                    id
                                    name
                                }
                                part
                                accepted
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
const createStudentEventQuery = /* GraphQL */ `
    mutation CreateStudentEvent(
        $input: CreateStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        createStudentEvent(input: $input, condition: $condition) {
            id
            studentId
            busId
            chaperoneId
            hotelId
            room
            checkin {
                thursday
                friday
                saturday
            }
            medicalReceived
            createdAt
            updatedAt
        }
    }
`;
const updateStudentEventQuery = /* GraphQL */ `
    mutation UpdateStudentEvent(
        $input: UpdateStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        updateStudentEvent(input: $input, condition: $condition) {
            id
            studentId
            busId
            chaperoneId
            hotelId
            room
            checkin {
                thursday
                friday
                saturday
            }
            medicalReceived
            createdAt
            updatedAt
        }
    }
`;
const deleteStudentEventQuery = /* GraphQL */ `
    mutation DeleteStudentEvent(
        $input: DeleteStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        deleteStudentEvent(input: $input, condition: $condition) {
            id
        }
    }
`;

const updateStudentQuery = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            eventId
        }
    }
`;
const listSelectionsQuery = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    instrument {
                        id
                        name
                    }
                    festival {
                        name
                        zone {
                            name
                        }
                    }
                    student {
                        id
                        name {
                            first
                            last
                            preferred
                        }
                        dob
                        gender
                        sex
                        grade
                        phone {
                            number
                        }
                        email {
                            address
                        }
                        school {
                            name {
                                legal
                            }
                        }
                        address {
                            line1
                            line2
                            city
                            state
                            zip
                            county
                        }
                        eventId
                        event {
                            id
                            studentId
                            busId
                            chaperoneId
                            hotelId
                            room
                            checkin {
                                thursday
                                friday
                                saturday
                            }
                            medicalReceived
                            notes {
                                id
                                type
                                text
                            }
                            createdAt
                            updatedAt
                        }
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;




