<template>
  <div>
    <template v-if="noText">
      {{ timeAgo(date)}}
    </template>
    <template v-else>
      {{ text }}{{ timeAgo(date)}}
    </template>
  </div>

</template>

<script>
  export default {
    name: 'LastModified',
    props: {
      date: {
        type: [Number, String, Date],
        required: true
      },
      text: {
        type: String,
        default: 'Last updated '
      },
      noText: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      timeAgo(date) {
        if(date === undefined || date === null) {
          return 0;
        }
        if(date.constructor === String) {
         date = Date.parse(date) // eslint-disable-line no-param-reassign
        }

        const timeFormats = [
          [60, 'Seconds', 1], // 60
          [120, '1 Minute Ago', '1 Minute From Now'], // 60*2
          [3600, 'Minutes', 60], // 60*60, 60
          [7200, '1 Hour Ago', '1 hour from now'], // 60*60*2
          [86400, 'Hours', 3600], // 60*60*24, 60*60
          [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
          [604800, 'Days', 86400], // 60*60*24*7, 60*60*24
          [1209600, 'Last Week', 'Next week'], // 60*60*24*7*4*2
          [2419200, 'Weeks', 604800], // 60*60*24*7*4, 60*60*24*7
          [4838400, 'Last Month', 'Next month'], // 60*60*24*7*4*2
          [29030400, 'Months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
          [58060800, 'Last Year', 'Next year'], // 60*60*24*7*4*12*2
          [2903040000, 'Years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
          [5806080000, 'Last Century', 'Next century'], // 60*60*24*7*4*12*100*2
          [58060800000, 'Centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ];

        let seconds = (+new Date() - date) / 1000;
        let token = 'Ago';
        let lastChoice = 1;

        if(seconds === 0) {
          return 'Just Now'
        }
        if(seconds < 0) {
          seconds = Math.abs(seconds);
          token = 'from now';
          lastChoice = 2;
        }
        let i = 0; let format;
        // eslint-disable-next-line no-cond-assign, no-plusplus
        while (format = timeFormats[i++]) {
          if (seconds < format[0]) {
            if (typeof format[2] === 'string') {
              return format[lastChoice];
            }
            return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
          }
        }
        return date;
      }
    }
  }
</script>
